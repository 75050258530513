body {
  color: #555555;
  line-height: 1.7;
  font-size: 14px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  overflow-x: hidden !important;
}

a {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  text-decoration: none;
}

a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}

p {
  margin-bottom: 8px;
}

p:last-child {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  color: #222222;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  line-height: 1.5;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

strong,
b {
  font-weight: 700;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn,
button {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: transparent;
}

.btn:active,
.btn:focus,
button:active,
button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input,
textarea {
  resize: none;
}

input:focus,
textarea:focus {
  outline: none;
}

.form-control:focus {
  border-color: #c29958;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group {
  margin-bottom: 1rem;
}

::-moz-selection {
  color: #fff;
  background: #c29958;
}

::selection {
  color: #fff;
  background: #c29958;
}

::-webkit-input-placeholder {
  color: #999;
  font-size: 13px;
  opacity: 1;
}

::-moz-placeholder {
  color: #999;
  font-size: 13px;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #999;
  font-size: 13px;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #999;
  font-size: 13px;
  opacity: 1;
}

::placeholder {
  color: #999;
  font-size: 13px;
  opacity: 1;
}

/* ----tab problem fix css ----*/
.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.tab-content .tab-pane.active {
  height: auto;
  opacity: 1;
  overflow: visible;
  visibility: visible;
}

/* ----tab problem fix css ----*/
/*------- modal fix start -------*/
/* .modal-dialog {
  max-width: 100%;
} */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog {
    max-width: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top-bar-right .product-short {

    width: 70% !important;
  }

  .modal-dialog {
    max-width: 700px;
  }
}


@media only screen and (min-width: 280px) and (max-width: 575px) {
  .banner-odd .mx-5 {
    margin-right: unset !important;
    margin-left: unset !important;

  }


}

.modal {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -99;
}

.modal.show {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  z-index: 99999999;
  -webkit-animation: slideInDown 0.4s forwards;
  animation: slideInDown 0.4s forwards;
}

.modal .modal-header {
  padding: 0 10px;
  border-bottom: none;
}

.modal .modal-header .close {
  color: #222222;
  font-size: 34px;
  opacity: 1;
  display: block;
  position: absolute;
  padding: 0;
  width: 30px;
  height: 30px;
  margin: 0;
  right: 10px;
  top: 10px;
  font-weight: 500;
  z-index: 999;
  line-height: 30px;
  border-radius: 50%;
}

.modal .modal-body {
  padding: 20px 20px 20px 20px;
}

@media only screen and (max-width: 479.98px) {
  .modal .modal-body {
    padding: 10px;
  }
}

/*------- modal fix end -------*/
/* ---- Sick Slider arrow style start ----*/
.slick-slider .slick-slide>div>div {
  vertical-align: middle;
}

.slick-arrow-style button.slick-arrow {
  top: 15%;
  left: 0;
  font-size: 20px;
  color: #777777;
  cursor: pointer;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.slick-arrow-style button.slick-arrow.slick-next {
  left: auto;
  right: 0;
}

.slick-arrow-style button.slick-arrow:hover {
  color: #bc1823;
}

.slick-arrow-style:hover button.slick-arrow {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.slick-arrow-style:hover button.slick-arrow.slick-prev {
  left: -60px;
  right: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .slick-arrow-style:hover button.slick-arrow.slick-prev {
    left: -20px;
  }
}

.slick-arrow-style:hover button.slick-arrow.slick-next {
  left: auto;
  right: -60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .slick-arrow-style:hover button.slick-arrow.slick-next {
    right: -20px;
  }
}

.slick-arrow-style_hero button.slick-arrow {
  font-size: 60px;
  left: 0;
  width: inherit;
  height: inherit;
  background-color: transparent;
  z-index: 1;
}

.slick-arrow-style_hero button.slick-arrow.slick-next {
  right: 0;
}

.slick-arrow-style_hero button.slick-arrow:hover {
  color: #bc1823;
  background-color: transparent;
}

.slick-arrow-style_hero:hover button.slick-arrow.slick-next {
  right: 15px;
}

.slick-arrow-style_hero:hover button.slick-arrow.slick-prev {
  left: 15px;
}

.slick-append {
  background-color: #fff;
  z-index: 2;
  margin-top: -2px;
}

.slick-append button {
  font-size: 30px;
  line-height: 1;
  position: inherit;
  display: inline-block;
  background-color: transparent;
  color: #555555;
}

.slick-append button:hover {
  color: #c29958;
}

/*--------- slick slider dot style start -------*/
.slick-dot-style ul.slick-dots {
  bottom: 15px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.slick-dot-style ul.slick-dots li {
  display: inline-block;
  margin-right: 10px;
}

.slick-dot-style ul.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dot-style ul.slick-dots li button {
  width: 14px;
  height: 14px;
  padding: 0;
  border: none;
  display: block;
  text-indent: -5000px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid #777777;
  background-color: transparent;
}

.slick-dot-style ul.slick-dots li.slick-active button {
  border-color: #bc1823;
  background-color: #bc1823;
}

.slick-row-3 .slick-list {
  margin: 0 -3px;
}

.slick-row-3 .slick-list .slick-slide {
  margin: 0 3px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-3 .slick-list {
    margin: 0 -3px !important;
  }

  .slick-sm-row-3 .slick-list .slick-slide {
    margin: 0 3px !important;
  }
}

.slick-row-4 .slick-list {
  margin: 0 -4px;
}

.slick-row-4 .slick-list .slick-slide {
  margin: 0 4px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-4 .slick-list {
    margin: 0 -4px !important;
  }

  .slick-sm-row-4 .slick-list .slick-slide {
    margin: 0 4px !important;
  }
}

.slick-row-5 .slick-list {
  margin: 0 -5px;
}

.slick-row-5 .slick-list .slick-slide {
  margin: 0 5px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-5 .slick-list {
    margin: 0 -5px !important;
  }

  .slick-sm-row-5 .slick-list .slick-slide {
    margin: 0 5px !important;
  }
}

.slick-row-6 .slick-list {
  margin: 0 -6px;
}

.slick-row-6 .slick-list .slick-slide {
  margin: 0 6px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-6 .slick-list {
    margin: 0 -6px !important;
  }

  .slick-sm-row-6 .slick-list .slick-slide {
    margin: 0 6px !important;
  }
}

.slick-row-7 .slick-list {
  margin: 0 -7px;
}

.slick-row-7 .slick-list .slick-slide {
  margin: 0 7px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-7 .slick-list {
    margin: 0 -7px !important;
  }

  .slick-sm-row-7 .slick-list .slick-slide {
    margin: 0 7px !important;
  }
}

.slick-row-8 .slick-list {
  margin: 0 -8px;
}

.slick-row-8 .slick-list .slick-slide {
  margin: 0 8px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-8 .slick-list {
    margin: 0 -8px !important;
  }

  .slick-sm-row-8 .slick-list .slick-slide {
    margin: 0 8px !important;
  }
}

.slick-row-9 .slick-list {
  margin: 0 -9px;
}

.slick-row-9 .slick-list .slick-slide {
  margin: 0 9px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-9 .slick-list {
    margin: 0 -9px !important;
  }

  .slick-sm-row-9 .slick-list .slick-slide {
    margin: 0 9px !important;
  }
}

.slick-row-10 .slick-list {
  margin: 0 -10px;
}

.slick-row-10 .slick-list .slick-slide {
  margin: 0 10px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-10 .slick-list {
    margin: 0 -10px !important;
  }

  .slick-sm-row-10 .slick-list .slick-slide {
    margin: 0 10px !important;
  }
}

.slick-row-11 .slick-list {
  margin: 0 -11px;
}

.slick-row-11 .slick-list .slick-slide {
  margin: 0 11px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-11 .slick-list {
    margin: 0 -11px !important;
  }

  .slick-sm-row-11 .slick-list .slick-slide {
    margin: 0 11px !important;
  }
}

.slick-row-12 .slick-list {
  margin: 0 -12px;
}

.slick-row-12 .slick-list .slick-slide {
  margin: 0 12px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-12 .slick-list {
    margin: 0 -12px !important;
  }

  .slick-sm-row-12 .slick-list .slick-slide {
    margin: 0 12px !important;
  }
}

.slick-row-13 .slick-list {
  margin: 0 -13px;
}

.slick-row-13 .slick-list .slick-slide {
  margin: 0 13px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-13 .slick-list {
    margin: 0 -13px !important;
  }

  .slick-sm-row-13 .slick-list .slick-slide {
    margin: 0 13px !important;
  }
}

.slick-row-14 .slick-list {
  margin: 0 -14px;
}

.slick-row-14 .slick-list .slick-slide {
  margin: 0 14px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-14 .slick-list {
    margin: 0 -14px !important;
  }

  .slick-sm-row-14 .slick-list .slick-slide {
    margin: 0 14px !important;
  }
}

.slick-row-15 .slick-list {
  margin: 0 -15px;
}

.slick-row-15 .slick-list .slick-slide {
  margin: 0 15px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-15 .slick-list {
    margin: 0 -15px !important;
  }

  .slick-sm-row-15 .slick-list .slick-slide {
    margin: 0 15px !important;
  }
}

.slick-slider .slick-slide>div {
  margin-bottom: 30px;
}

.slick-slider .slick-slide>div:last-child {
  margin-bottom: 0;
}

/* ----scroll to top css start ----*/
.scroll-top {
  bottom: 50px;
  cursor: pointer;
  height: 50px;
  position: fixed;
  right: 20px;
  text-align: center;
  width: 50px;
  z-index: 9999;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  background-color: #bc1823;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 767.98px) {
  /* .scroll-top {
    display: none;
  } */
}

.scroll-top i {
  line-height: 50px;
  color: #fff;
  font-size: 25px;
}

/* .scroll-top.not-visible {
  bottom: -50px;
  visibility: hidden;
  opacity: 0;
} */
.scroll-top:hover {
  background-color: #222222;
}

/* ----scroll to top css end ----*/
.container {
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
}

.row {
  --bs-gutter-x: 30px;
}

.custom-container {
  max-width: 1800px;
}

.row-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.row-0>[class*=col] {
  padding-left: 0px;
  padding-right: 0px;
}

.row-1 {
  margin-left: -0.5px;
  margin-right: -0.5px;
}

.row-1>[class*=col] {
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.row-2 {
  margin-left: -1px;
  margin-right: -1px;
}

.row-2>[class*=col] {
  padding-left: 1px;
  padding-right: 1px;
}

.row-3 {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.row-3>[class*=col] {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.row-4 {
  margin-left: -2px;
  margin-right: -2px;
}

.row-4>[class*=col] {
  padding-left: 2px;
  padding-right: 2px;
}

.row-5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.row-5>[class*=col] {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.row-6 {
  margin-left: -3px;
  margin-right: -3px;
}

.row-6>[class*=col] {
  padding-left: 3px;
  padding-right: 3px;
}

.row-7 {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.row-7>[class*=col] {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.row-8 {
  margin-left: -4px;
  margin-right: -4px;
}

.row-8>[class*=col] {
  padding-left: 4px;
  padding-right: 4px;
}

.row-9 {
  margin-left: -4.5px;
  margin-right: -4.5px;
}

.row-9>[class*=col] {
  padding-left: 4.5px;
  padding-right: 4.5px;
}

.row-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.row-10>[class*=col] {
  padding-left: 5px;
  padding-right: 5px;
}

.row-11 {
  margin-left: -5.5px;
  margin-right: -5.5px;
}

.row-11>[class*=col] {
  padding-left: 5.5px;
  padding-right: 5.5px;
}

.row-12 {
  margin-left: -6px;
  margin-right: -6px;
}

.row-12>[class*=col] {
  padding-left: 6px;
  padding-right: 6px;
}

.row-13 {
  margin-left: -6.5px;
  margin-right: -6.5px;
}

.row-13>[class*=col] {
  padding-left: 6.5px;
  padding-right: 6.5px;
}

.row-14 {
  margin-left: -7px;
  margin-right: -7px;
}

.row-14>[class*=col] {
  padding-left: 7px;
  padding-right: 7px;
}

.row-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.row-15>[class*=col] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.row-16 {
  margin-left: -8px;
  margin-right: -8px;
}

.row-16>[class*=col] {
  padding-left: 8px;
  padding-right: 8px;
}

.row-17 {
  margin-left: -8.5px;
  margin-right: -8.5px;
}

.row-17>[class*=col] {
  padding-left: 8.5px;
  padding-right: 8.5px;
}

.row-18 {
  margin-left: -9px;
  margin-right: -9px;
}

.row-18>[class*=col] {
  padding-left: 9px;
  padding-right: 9px;
}

.row-19 {
  margin-left: -9.5px;
  margin-right: -9.5px;
}

.row-19>[class*=col] {
  padding-left: 9.5px;
  padding-right: 9.5px;
}

.row-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.row-20>[class*=col] {
  padding-left: 10px;
  padding-right: 10px;
}

.row-21 {
  margin-left: -10.5px;
  margin-right: -10.5px;
}

.row-21>[class*=col] {
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.row-22 {
  margin-left: -11px;
  margin-right: -11px;
}

.row-22>[class*=col] {
  padding-left: 11px;
  padding-right: 11px;
}

.row-23 {
  margin-left: -11.5px;
  margin-right: -11.5px;
}

.row-23>[class*=col] {
  padding-left: 11.5px;
  padding-right: 11.5px;
}

.row-24 {
  margin-left: -12px;
  margin-right: -12px;
}

.row-24>[class*=col] {
  padding-left: 12px;
  padding-right: 12px;
}

.row-25 {
  margin-left: -12.5px;
  margin-right: -12.5px;
}

.row-25>[class*=col] {
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.row-26 {
  margin-left: -13px;
  margin-right: -13px;
}

.row-26>[class*=col] {
  padding-left: 13px;
  padding-right: 13px;
}

.row-27 {
  margin-left: -13.5px;
  margin-right: -13.5px;
}

.row-27>[class*=col] {
  padding-left: 13.5px;
  padding-right: 13.5px;
}

.row-28 {
  margin-left: -14px;
  margin-right: -14px;
}

.row-28>[class*=col] {
  padding-left: 14px;
  padding-right: 14px;
}

.row-29 {
  margin-left: -14.5px;
  margin-right: -14.5px;
}

.row-29>[class*=col] {
  padding-left: 14.5px;
  padding-right: 14.5px;
}

.row-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.row-30>[class*=col] {
  padding-left: 15px;
  padding-right: 15px;
}

/*------- short classes start -------*/
.section-padding {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 767.98px) {
  .section-padding {
    padding-top: 63px;
    padding-bottom: 63px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mtn-20 {
  margin-top: -20px;
}

.mbn-30 {
  margin-bottom: -30px;
}

.mtn-30 {
  margin-top: -30px;
}

.ptb-30 {
  padding: 30px 0;
}

/*------- short classes end -------*/
/*----------- header top area start -----------*/
@media only screen and (min-width: 1600px) {
  .header-wide {
    padding: 0 85px;
  }
}

/* @media only screen and (min-width: 1200px) {
  .header-wide .container {
    max-width: 100%;
  }
} */

.header-top {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}

.header-top-settings ul li {
  color: #555555;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  margin-left: 40px;
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
}

.header-top-settings ul li:before {
  top: 50%;
  left: 0;
  width: 1px;
  height: 15px;
  content: "";
  position: absolute;
  background-color: #efefef;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-top-settings ul li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.header-top-settings ul li:first-child:before {
  display: none;
}

.header-top-settings ul li .dropdown-list {
  top: 100%;
  right: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 15px 15px 20px;
  width: 150px;
  z-index: 9;
  text-align: left;
  background-color: #fff;
  pointer-events: none;
  border: 1px solid #efefef;
}

.header-top-settings ul li .dropdown-list li {
  margin-left: 0;
  padding: 0;
}

.header-top-settings ul li .dropdown-list li a {
  color: #555555;
  font-size: 13px;
  display: block;
  padding: 5px 0 3px;
  text-transform: capitalize;
}

.header-top-settings ul li .dropdown-list li a:hover {
  color: #c29958;
}

.header-top-settings ul li .dropdown-list li a img {
  vertical-align: inherit;
}

.header-top-settings ul li .dropdown-list li:before {
  display: none;
}

.header-top-settings ul li:hover .dropdown-list {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.welcome-message p {
  color: #555555;
  font-size: 14px;
  line-height: 1;
}

/*----------- header top area end -----------*/
/*---------- header mini cart start ----------*/
.header-configure-area {
  padding-right: 17px;
}

.header-configure-area ul li {
  display: inline-block;
  margin-left: 25px;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header-configure-area ul li {
    margin-left: 14px;
  }
}

.header-configure-area ul li a {
  font-size: 26px;
  line-height: 1;
  color: #222222;
  position: relative;
}

.header-configure-area ul li a .notification {
  top: -8px;
  right: -6px;
  position: absolute;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
  background-color: #bc1823;
}

.header-configure-area ul li a:hover {
  color: #bc1823;
}

.header-configure-area ul li:first-child {
  margin-left: 0;
}

.header-configure-area ul li.user-hover {
  position: relative;
}

.header-configure-area ul li.user-hover .dropdown-list {
  position: absolute;
  top: 100%;
  right: 0;
  width: 150px;
  padding: 20px;
  background-color: #fff;
  z-index: 11;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #efefef;
}

.header-configure-area ul li.user-hover .dropdown-list li {
  display: block;
  margin-left: 0;
}

.header-configure-area ul li.user-hover .dropdown-list li a {
  color: #555555;
  font-size: 13px;
  line-height: 1;
  display: block;
  padding: 8px 0;
  text-transform: capitalize;
}

.header-configure-area ul li.user-hover .dropdown-list li a:hover {
  color: #bc1823;
}

.header-configure-area ul li.user-hover:hover .dropdown-list {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

/*----------- header mini cart area end -----------*/
/*------- header search area start -------*/
.header-search-container {
  position: relative;
}

.header-search-box {
  position: relative;
}

.header-search-box:hover .header-search-btn {
  color: #c29958;
}

.header-search-btn {
  font-size: 25px;
  line-height: 1;
  left: 10px;
  top: 50%;
  width: 30px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-search-field {
  border: none;
  background-color: #f7f7f7;
  border-radius: 30px;
  padding: 10px 20px 10px 50px;
  width: 100%;
}

.search-trigger {
  font-size: 26px;
  color: #222222;
}

.search-box-open {
  top: 100%;
  right: 0;
  width: 300px;
  position: absolute;
  z-index: 5;
  display: block !important;
}

.search-box-open .header-search-field {
  height: 44px;
  border: 1px solid #ddd;
}

/*------- header search area end -------*/
/*-------- header social link start --------*/
.header-social-link a {
  color: #777777;
  font-size: 17px;
  display: inline-block;
  line-height: 1;
  margin-right: 20px;
}

.header-social-link a:last-child {
  margin-right: 0;
}

.header-social-link a:hover {
  color: #c29958;
}

/*-------- header social link end --------*/
/*---------- main menu style start ---------*/
.main-menu ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  
}

.main-menu ul li {
  position: relative;
}

.main-menu ul li a {
  color: #222222;
  font-size: 15px;
  padding: 30px 30px;
  font-weight: 400;
  display: block;
  text-transform: capitalize;
}

.main-menu ul li a i {
  font-size: 14px;
  padding: 0 3px;
}

.main-menu ul li:first-child a {
  padding-left: 0;
}

.main-menu ul li:hover>a,
.main-menu ul li.active>a {
  color: #bc1823;
}

.main-menu ul li ul.dropdown {
  top: calc(100% - 1px);
  left: 0;
  width: 220px;
  position: absolute;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  pointer-events: none;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  border: 1px solid #efefef;
}

.main-menu ul li ul.dropdown li {
  margin-right: 0;
  border-right: none;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.main-menu ul li ul.dropdown li a {
  color: #555555;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  text-transform: capitalize;
  position: relative;
  border-bottom: 1px dashed #efefef;
  z-index: 1;
}

.main-menu ul li ul.dropdown li a:before {
  width: 10px;
  height: 1px;
  left: 20px;
  top: 50%;
  content: " ";
  position: absolute;
  background-color: #c29958;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: -1;
}

.main-menu ul li ul.dropdown li a i {
  float: right;
  padding-top: 5px;
}

.main-menu ul li ul.dropdown li:hover>a {
  color: #bc1823;
  background-color: #fff;
  padding-left: 35px;
}

.main-menu ul li ul.dropdown li:hover>a:before {
  opacity: 1;
  visibility: visible;
}

.main-menu ul li ul.dropdown li:hover>ul.dropdown {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.main-menu ul li ul.dropdown li ul.dropdown {
  top: 100%;
  left: 100%;
  position: absolute;
  width: 250px;
  opacity: 0;
  visibility: hidden;
}

.main-menu ul li ul.dropdown li:last-child a {
  border-bottom: none;
}

.main-menu ul li ul.megamenu {
  width: 100%;
  max-width: 1110px;
  padding: 30px;
  left: 50%;
  -webkit-transform: translate(-50%, 20px);
  -ms-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li ul.megamenu {
    max-width: 100%;
  }
}

.main-menu ul li ul.megamenu li {
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}

.main-menu ul li ul.megamenu li.mega-title {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.main-menu ul li ul.megamenu li.mega-title span {
  position: relative;
  margin-bottom: 15px;
  display: inline-block;
}

.main-menu ul li ul.megamenu li.mega-title span:before,
.main-menu ul li ul.megamenu li.mega-title span:after {
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 2px;
  content: " ";
  position: absolute;
  background-color: #ebebeb;
}

.main-menu ul li ul.megamenu li.mega-title span:after {
  width: 50%;
  background-color: #c29958;
}

.main-menu ul li ul.megamenu li a {
  padding: 6px 0;
  border-bottom: none;
}

.main-menu ul li ul.megamenu li a:before {
  left: 0;
}

.main-menu ul li ul.megamenu li ul li {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.main-menu ul li ul.megamenu li:hover>a {
  color: #c29958;
  padding-left: 15px;
}

.main-menu ul li ul.megamenu li.megamenu-banners {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  margin-top: 25px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding-right: 10px;
}

.main-menu ul li ul.megamenu li.megamenu-banners img {
  width: 100%;
}

.main-menu ul li ul.megamenu li.megamenu-banners a:before {
  display: none;
}

.main-menu ul li ul.megamenu li.megamenu-banners:last-child {
  padding-right: 0;
  padding-left: 10px;
}

.main-menu ul li ul.megamenu li.megamenu-banners:hover {
  opacity: 0.6;
}

.main-menu ul li ul.megamenu li.megamenu-banners:hover a {
  padding-left: 0 !important;
}

.main-menu ul li:hover ul.dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}

.main-menu ul li:hover ul.megamenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.main-menu ul.header-style-4>li a {
  padding: 25px 15px;
}

.main-menu ul.header-style-4>li:first-child>a {
  padding-left: 0;
}

/*---------- main menu style end ---------*/
/*------ sticky menu style start ------*/
.header-transparent {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.sticky.is-sticky {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  -webkit-animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
  animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
}

@media only screen and (min-width: 1600px) {
  .sticky.is-sticky {
    padding: 0 85px;
  }
}

.sticky.is-sticky .main-menu li a {
  padding: 20px 15px;
}

.sticky.is-sticky .main-menu li:first-child a {
  padding-left: 0;
}

.sticky.is-sticky .main-menu li .dropdown li a {
  padding: 10px 20px;
}

.sticky.is-sticky .main-menu li .dropdown li:hover>a {
  padding-left: 35px;
}

.sticky.is-sticky .main-menu li .megamenu li a {
  padding: 6px 0;
}

.sticky.is-sticky .main-menu li .megamenu li:hover>a {
  padding-left: 15px;
}

/*------ sticky menu style end ------*/
/*------- Buttons Style here -------*/
.btn {
  font-size: 14px;
  color: #222222;
  line-height: 1;
}

.btn-text {
  font-size: 16px;
  position: relative;
  pointer-events: visible;
  text-transform: capitalize;
}

.btn-text:before {
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #222222;
}

.btn-text:hover {
  color: #c29958;
}

.btn-text:hover:before {
  background-color: #c29958;
}

.btn-cart {
  padding: 12px 25px;
  background-color: #fff;
  border-radius: 30px;
  text-transform: capitalize;
  -webkit-box-shadow: 0 2px 1px 0.5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 1px 0.5px rgba(0, 0, 0, 0.05);
}

.btn-cart:hover {
  color: #fff;
  background-color: #bc1823;
}

.btn-cart2 {
  height: 40px;
  color: #fff;
  line-height: 40px;
  border-radius: 50px;
  padding: 0 25px;
  background-color: #bc1823;
}

.btn-cart2:hover {
  color: #fff;
  background-color: #222222;
}

.btn-sqr {
  color: #fff;
  font-size: 15px;
  border-radius: 0;
  background-color: #bc1823;
  padding: 12px 25px;
}

.btn-sqr:hover {
  color: #fff;
  background-color: #222222;
}

/*----- mobile menu start -----*/
.mobile-header {
  padding: 10px 0;
}

.mobile-header-top {
  border-bottom: 1px solid #efefef;
}

.mobile-header-top .header-top-settings {
  float: none;
}

.mobile-logo {
  max-width: 100px;
  width: 100%;
}

.mobile-main-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mobile-main-header .mobile-menu-toggler {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
  line-height: 1;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a {
  font-size: 25px;
  line-height: 1;
  color: #222222;
  position: relative;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a:hover {
  color: #c29958;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification {
  font-size: 13px;
  color: #fff;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  right: -4px;
  top: -4px;
  position: absolute;
  background-color: #c29958;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
  margin-left: 25px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span {
  width: 25px;
  height: 2px;
  display: block;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: #222222;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span:nth-child(2),
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span:nth-child(3) {
  margin-top: 5px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn:hover span {
  background-color: #c29958;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn:hover span:nth-child(1),
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn:hover span:nth-child(3) {
  width: 20px;
}

.mobile-navigation {
  overflow: hidden;
  /* max-height: 250px; */
}

.mobile-navigation nav {
  height: 100%;
}

.mobile-menu {
  margin-top: 30px;
  height: 100%;
  overflow: auto;
  padding-right: 30px;
  margin-right: -30px;
}

@media only screen and (max-width: 479.98px) {
  .mobile-menu {
    margin-top: 15px;
  }
}

.mobile-menu li>a {
  font-size: 15px;
  color: #222222;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
}

.mobile-menu li>a:hover {
  color: #bc1823;
}

.mobile-menu li ul li {
  border: none;
}

.mobile-menu li ul li a {
  font-size: 14px;
  text-transform: capitalize;
  padding: 10px 0 8px;
}

.mobile-menu li.menu-item-has-children {
  display: block;
  position: relative;
}

.mobile-menu li.menu-item-has-children .dropdown {
  padding-left: 15px;
}

.mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #222222;
  -webkit-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
}

.mobile-menu li.menu-item-has-children .menu-expand i:before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  border-bottom: 1px solid #222222;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.mobile-menu li.menu-item-has-children.active>.menu-expand i:before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

/*----- mobile menu end -----*/
/*------ offcanvas widget area start ------*/
.offcanvas-widget-area {
  margin-top: auto;
  padding-bottom: 30px;
}

@media only screen and (max-width: 479.98px) {
  .offcanvas-widget-area {
    padding-bottom: 0;
  }
}

.off-canvas-contact-widget li {
  color: #555555;
  font-size: 15px;
  margin-bottom: 5px;
}

.off-canvas-contact-widget li i {
  width: 20px;
}

.off-canvas-contact-widget li a {
  color: #555555;
}

.off-canvas-contact-widget li a:hover {
  color: #c29958;
}

.off-canvas-social-widget {
  margin-top: 20px;
}

.off-canvas-social-widget a {
  color: #555555;
  font-size: 18px;
  display: inline-block;
  margin-right: 15px;
}

.off-canvas-social-widget a:hover {
  color: #c29958;
}

/*------ offcanvas widget area end ------*/
.off-canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: 9999;
}

.off-canvas-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.off-canvas-wrapper.open .off-canvas-inner-content {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.off-canvas-wrapper .off-canvas-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  cursor: url("../img/icon/cancel.png"), auto;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.off-canvas-wrapper .off-canvas-inner-content {
  background-color: #fff;
  width: 300px;
  padding: 20px;
  height: 100%;
  position: relative;
  -webkit-transform: translateX(calc(-100% - 50px));
  -ms-transform: translateX(calc(-100% - 50px));
  transform: translateX(calc(-100% - 50px));
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
  .off-canvas-wrapper .off-canvas-inner-content {
    width: 260px;
    padding: 15px;
  }
}

.off-canvas-wrapper .btn-close-off-canvas {
  top: 0;
  left: 100%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: #bc1823;
}

.off-canvas-wrapper .btn-close-off-canvas i {
  font-size: 40px;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  display: block;
  line-height: 40px;
}

.off-canvas-wrapper .btn-close-off-canvas:hover i {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.off-canvas-wrapper .off-canvas-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
  height: 100%;
}

.search-box-offcanvas form {
  position: relative;
}

.search-box-offcanvas form input {
  color: #666;
  font-size: 13px;
  width: 100%;
  height: 40px;
  border: none;
  padding: 0 40px 0 10px;
  background-color: #f2f2f2;
}

.search-box-offcanvas form .search-btn {
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 42px;
  font-size: 20px;
  color: #222222;
  position: absolute;
}

.search-box-offcanvas form .search-btn:hover {
  color: #c29958;
}

/*------- mobile top bar settings start -------*/
.mobile-settings {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #efefef;
}

.mobile-settings li {
  margin-bottom: 5px;
}

.mobile-settings .nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle {
  font-size: 14px;
  color: #555555;
  cursor: pointer;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle i {
  font-size: 12px;
  padding-left: 5px;
  vertical-align: middle;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle:hover {
  color: #c29958;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle:after {
  display: none;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu {
  padding: 0;
  border-color: #efefef;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu.show {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu .dropdown-item {
  font-size: 13px;
  color: #555555;
  padding: 6px 15px;
}

/*------- mobile top bar settings end -------*/
/*-------- off canvas mini cart start --------*/
.minicart-inner {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
}

.minicart-inner .offcanvas-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: url("../img/icon/cancel.png"), auto;
}

.minicart-inner.show {
  opacity: 1;
  visibility: visible;
}

.minicart-inner.show .minicart-inner-content {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.minicart-inner .minicart-close {
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #c29958;
  color: #fff;
  font-size: 50px;
  cursor: pointer;
  top: 0;
  right: 375px;
  position: absolute;
}

.minicart-inner .minicart-close i {
  display: block;
  line-height: 50px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.minicart-inner .minicart-close:hover i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.minicart-inner .minicart-inner-content {
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  max-width: 375px;
  position: absolute;
  background-color: #fff;
  -webkit-transform: translateX(calc(100% + 50px));
  -ms-transform: translateX(calc(100% + 50px));
  transform: translateX(calc(100% + 50px));
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 50px 20px;
}

.minicart-content-box {
  overflow: auto;
  height: 100%;
  padding-right: 30px;
  margin-right: -15px;
}

.minicart-item-wrapper {
  border-bottom: 1px solid #efefef;
}

.minicart-item-wrapper ul li {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #efefef;
}

.minicart-item-wrapper ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.minicart-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.minicart-thumb {
  max-width: 85px;
  -webkit-flex-basis: 85px;
  -ms-flex-preferred-size: 85px;
  flex-basis: 85px;
}

.minicart-content {
  padding: 0 10px;
  max-width: calc(100% - 115px);
  -webkit-flex-basis: calc(100% - 115px);
  -ms-flex-preferred-size: calc(100% - 115px);
  flex-basis: calc(100% - 115px);
}

.minicart-content .product-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: 10px;
}

.minicart-content .product-name a {
  color: #222222;
}

.minicart-content .product-name a:hover {
  color: #c29958;
}

.minicart-content .cart-quantity {
  font-size: 12px;
  color: #555555;
  line-height: 1;
}

.minicart-content .cart-quantity strong {
  font-size: 16px;
  font-weight: 400;
  vertical-align: text-bottom;
}

.minicart-content .cart-price {
  color: #c29958;
  font-size: 14px;
  line-height: 1;
}

.minicart-remove {
  max-width: 30px;
  -webkit-flex-basis: 30px;
  -ms-flex-preferred-size: 30px;
  flex-basis: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 1;
  color: #222222;
}

.minicart-remove:hover {
  color: #c29958;
}

.minicart-pricing-box {
  border-bottom: 1px solid #efefef;
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-top: 15px;
}

.minicart-pricing-box li {
  margin-bottom: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.minicart-pricing-box li span {
  font-size: 14px;
  color: #222222;
  text-transform: capitalize;
}

.minicart-pricing-box li span strong {
  color: #c29958;
  font-weight: 400;
}

.minicart-pricing-box li.total span {
  font-size: 16px;
}

.minicart-pricing-box li:last-child {
  margin-bottom: 0;
}

.minicart-button a {
  color: #222222;
  font-size: 14px;
  display: block;
  font-weight: 700;
  line-height: 1;
  padding: 17px 0;
  background-color: #f3f3f3;
  border-radius: 40px;
  text-align: center;
  margin-bottom: 10px;
}

.minicart-button a i {
  padding-right: 5px;
}

.minicart-button a:hover {
  color: #fff;
  letter-spacing: 1.1px;
  background-color: #c29958;
}

.minicart-button a:last-child {
  margin-bottom: 0;
}

/*-------- off canvas mini cart end --------*/
/*------ hero slider area css start ------*/
/*-------------------------
02. Slider area
--------------------------*/
.hero-slider-item {
  height: 530px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 1600px) {
  .hero-slider-item {
    height: 670px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .hero-slider-item {
    height: 400px;
  }
}

@media only screen and (max-width: 575.98px) {
  .hero-slider-item {
    height: 350px;
  }
}

.hero-style-five .hero-slider-item {
  height: 800px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-style-five .hero-slider-item {
    height: 530px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .hero-style-five .hero-slider-item {
    height: 400px;
  }
}

@media only screen and (max-width: 575.98px) {
  .hero-style-five .hero-slider-item {
    height: 350px;
  }
}

.hero-slider-content {
  position: relative;
}

/* @media only screen and (max-width: 767.98px) {
  .hero-slider-content {
    padding-right: 150px;
  }
} */

@media only screen and (max-width: 479.98px) {
  .hero-slider-content {
    padding-right: 0;
  }
}

.hero-slider-content .slide-title {
  font-size: 60px;
  font-weight: normal;
  line-height: 1;
}

.hero-slider-content .slide-title span {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content .slide-title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767.98px) {
  .hero-slider-content .slide-title {
    font-size: 40px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 575.98px) {
  .hero-slider-content .slide-title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 479.98px) {
  .hero-slider-content .slide-title {
    font-size: 25px;
  }
}

.hero-slider-content .slide-desc {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.3;
  color: #222222;
  padding-top: 20px;
}

@media only screen and (max-width: 575.98px) {
  .home-banner .banner-h img {
    height: 230px ;
}

  .carousel-caption {
    position: absolute;
    bottom: 2.25rem;
}
  .hero-slider-content .slide-desc {
    padding-top: 10px;
    display: none;
  }
}

.btn-hero {
  color: #fff;
  font-size: 15px;
  line-height: 1;
  padding: 14px 30px;
  display: inline-block;
  border-radius: 50px;
  background-color: #bc1823;
  margin-top: 38px;
}

@media only screen and (max-width: 575.98px) {
  .btn-hero {
    margin-top: 25px;
  }
}

.btn-hero:hover {
  color: #fff;
  background-color: #222222;
}

.hero-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}



/*------ hero slider area css end ------*/
.slick-active .hero-slider-content.slide-1 h2 {
  -webkit-animation: bounceIn 2s linear alternate;
  animation: bounceIn 2s linear alternate;
}

.slick-active .hero-slider-content.slide-1 h4 {
  -webkit-animation: zoomIn 1s linear alternate;
  animation: zoomIn 1s linear alternate;
}

.slick-active .hero-slider-content.slide-1 a {
  -webkit-animation: slideInUp 0.5s linear alternate;
  animation: slideInUp 0.5s linear alternate;
}

.slick-active .hero-slider-content.slide-2 h2 {
  -webkit-animation: bounceIn 1.5s linear alternate;
  animation: bounceIn 1.5s linear alternate;
}

.slick-active .hero-slider-content.slide-2 h4 {
  -webkit-animation: fadeInLeft 1s linear alternate;
  animation: fadeInLeft 1s linear alternate;
}

.slick-active .hero-slider-content.slide-2 a {
  -webkit-animation: slideInUp 0.5s linear alternate;
  animation: slideInUp 0.5s linear alternate;
}

.slick-active .hero-slider-content.slide-3 h2 {
  -webkit-animation: fadeInUp 1000ms linear alternate;
  animation: fadeInUp 1000ms linear alternate;
}

.slick-active .hero-slider-content.slide-3 h4 {
  -webkit-animation: bounceIn 600ms linear alternate;
  animation: bounceIn 600ms linear alternate;
}

.slick-active .hero-slider-content.slide-3 a {
  -webkit-animation: fadeInUp 1400ms linear alternate;
  animation: fadeInUp 1400ms linear alternate;
}

.slider-item {
  position: relative;
  overflow: hidden;
}

.slider-item:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background-color: #000;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: 0.4s;
}

@media only screen and (max-width: 767.98px) {
  .slider-item:before {
    opacity: 0.6;
    visibility: visible;
  }
}

.slider-item:hover:before {
  opacity: 0.6;
  visibility: visible;
}

.slider-item:hover .slider-thumb img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.slider-item:hover .slider-item-content {
  -webkit-transform: translateY(-50%) scale(1);
  -ms-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  opacity: 1;
  visibility: visible;
}

.slider-item .slider-thumb img {
  width: 100%;
}

.slider-item-content {
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  text-align: center;
  padding: 0 30px;
  z-index: 2;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateY(-50%) scale(0.8);
  -ms-transform: translateY(-50%) scale(0.8);
  transform: translateY(-50%) scale(0.8);
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 767.98px) {
  .slider-item-content {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
  }
}

.slider-item-content h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.1;
  padding-bottom: 6px;
  overflow-wrap: break-word;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-item-content h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479.98px) {
  .slider-item-content h2 {
    font-size: 26px;
  }
}

.slider-item-content h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
}

@media only screen and (max-width: 479.98px) {
  .slider-item-content h3 {
    font-size: 22px;
  }
}

.slider-item-content .btn-text {
  margin-top: 15px;
  color: #fff;
}

.slider-item-content .btn-text:before {
  background-color: #fff;
}

.slider-item-content .btn-text:hover {
  color: #c29958;
}

.slider-item-content .btn-text:hover:before {
  background-color: #c29958;
}

/*------ service policy start ------*/
.policy-block {
  border-bottom: 1px solid #efefef;
}

.policy-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  margin-bottom: -3px;
}

@media only screen and (max-width: 767.98px) {
  .policy-item {
    margin-bottom: -6px;
  }
}

@media only screen and (max-width: 575.98px) {
  .policy-item {
    display: block;
    text-align: center;
  }
}

.policy-icon {
  font-size: 30px;
  line-height: 1;
  color: #c29958;
  padding-right: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .policy-icon {
    padding-right: 12px;
  }
}

@media only screen and (max-width: 575.98px) {
  .policy-icon {
    padding-right: 0;
    margin-bottom: 10px;
  }
}

.policy-content p {
  color: #777;
  padding-top: 9px;
}

/*------ service policy end ------*/
/*------ banner statistics style start ------*/
/* 1.0 banner statistics */
.banner-statistics {
  position: relative;
}

.banner-statistics img {
  margin: auto;
}

/* .banner-statistics:hover img {
  opacity: 0.7;
} */

.banner-content {
  right: 30px;
  top: 50%;
  position: absolute;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
    text-wrap: wrap;
    width: 44%;
    margin-left: auto;

}


@media only screen and (min-width: 576px) and (max-width: 740px) {

.home-banner-right {
  width: 77%;
}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .carousel-caption {
    display: flex;
    align-items: center;
}
.carousel-caption {
  position: absolute;
  bottom: 0.35rem !important;
}

  .banner-content {
    right: 20px;
  }
  .banner-content_style2 {
    left: 40%;
    right: unset !important;

   
}
}

@media only screen and (max-width: 767.98px) {
  .banner-content {
    right: 20px;
  }
  .banner-content_style2 {
    left: 40% !important;

    right: unset !important;

   
  }
}


@media only screen and (max-width: 575.98px) {
  .banner-content {
    right: 55px;
  }
}

@media only screen and (max-width: 479.98px) {
  .banner-content {
    right: 20px;
  }
  .banner-content_style2 {
    right: unset;

 
}
}

.banner-content_style2 {
  left: 40%;
  top: auto;
  bottom: 40px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.banner-content_style2 .banner-text3 {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.banner-content_style2 .banner-text3 a {
  color: #222222;
  pointer-events: visible;
}

.banner-content_style2 .banner-text3 a:hover {
  color: #bc1823;
}

.banner-content_style3 {
  left: 40px;
  right: auto;
}

.banner-text1 {
  font-size: 15px;
  line-height: 1.1;
  padding-bottom: 15px;
  color: #777777;
  text-transform: uppercase;
}

@media only screen and (max-width: 767.98px) {
  .banner-text1 {
    font-size: 14px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 575.98px) {
  .banner-text1 {
    font-size: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 479.98px) {
  .banner-text1 {
    font-size: 14px;
    padding-bottom: 10px;
  }
}

.banner-text2 {
  padding-bottom: 34px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.banner-text2 span {
  display: block;
}

@media only screen and (max-width: 767.98px),
only screen and (max-width: 479.98px) {
  .banner-text2 span {
    display: inline-block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-text2 {
    font-size: 20px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767.98px) {
  .banner-text2 {
    font-size: 17px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 575.98px) {
  .banner-text2 {
    font-size: 25px;
    padding-bottom: 34px;
  }
}

@media only screen and (max-width: 479.98px) {
  .banner-text2 {
    font-size: 17px;
    padding-bottom: 10px;
  }
}

/*------ banner statistics style end ------*/
/*------ section title start ------*/
.section-title {
  margin-top: -7px;
  margin-bottom: 37px;
}

.section-title .title {
  text-transform: capitalize;
  background: -webkit-gradient(linear, left top, right top, from(#bc1823), color-stop(#000), to(#bc1823));
  background: -webkit-linear-gradient(left, #bc1823, #000, #bc1823);
  background: -o-linear-gradient(left, #bc1823, #000, #bc1823);
  background: linear-gradient(90deg, #bc1823, #000, #bc1823);
  background-repeat: no-repeat;
  background-size: 90%;
  -webkit-animation: shine 3s linear infinite;
  animation: shine 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@media only screen and (max-width: 767.98px) {
  .section-title .title {
    font-size: 26px;
  }
}

.section-title .sub-title {
  font-size: 15px;
  padding-top: 6px;
  color: #555555;
}

@-webkit-keyframes shine {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 100%;
  }
}

@keyframes shine {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 100%;
  }
}

.section-title-append {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  margin-top: -4px;
  margin-bottom: 18px;
  line-height: 1;
}

.section-title-append:after {
  top: 50%;
  right: 0;
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  background-color: #efefef;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  margin-top: -3px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-append:after {
    margin-top: 2px;
  }
}

.section-title-append h4 {
  display: inline-block;
  line-height: 1;
  text-transform: capitalize;
  background-color: #fff;
  position: relative;
  z-index: 2;
  padding-right: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-append h4 {
    font-size: 16px;
  }
}

/*------ section title end ------*/
/*------- common style css start -------*/
/*-------- Custom Checkbox Style start --------*/
.custom-checkbox.custom-control,
.custom-radio.custom-control {
  min-height: auto;
  padding-left: 1.5rem;
}

.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
  font-size: 14px;
  line-height: 1;
  padding-left: 10px;
  font-weight: 400;
  display: block;
  cursor: pointer;
  position: relative;
}

.custom-checkbox .custom-control-label:before,
.custom-radio .custom-control-label:before {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 0;
  height: 15px;
  top: 0;
  width: 15px;
  content: "";
  position: absolute;
  left: -1.5rem;
}

.custom-checkbox .custom-control-label:after,
.custom-radio .custom-control-label:after {
  background-size: 10px 10px;
  height: 15px;
  top: 0;
  content: "";
  position: absolute;
  left: -1.5rem;
}

.custom-checkbox .custom-control-label a,
.custom-radio .custom-control-label a {
  color: #222222;
  font-weight: 500;
}

.custom-checkbox .custom-control-label a:hover,
.custom-radio .custom-control-label a:hover {
  color: #c29958;
}

.custom-checkbox input,
.custom-checkbox .custom-control-input,
.custom-radio input,
.custom-radio .custom-control-input {
  outline: none;
  display: none;
}

.custom-checkbox input:focus~.custom-control-label:before,
.custom-checkbox input:focus label:before,
.custom-checkbox .custom-control-input:focus~.custom-control-label:before,
.custom-checkbox .custom-control-input:focus label:before,
.custom-radio input:focus~.custom-control-label:before,
.custom-radio input:focus label:before,
.custom-radio .custom-control-input:focus~.custom-control-label:before,
.custom-radio .custom-control-input:focus label:before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-checkbox input:checked~.custom-control-label:before,
.custom-checkbox input:checked label:before,
.custom-checkbox .custom-control-input:checked~.custom-control-label:before,
.custom-checkbox .custom-control-input:checked label:before,
.custom-radio input:checked~.custom-control-label:before,
.custom-radio input:checked label:before,
.custom-radio .custom-control-input:checked~.custom-control-label:before,
.custom-radio .custom-control-input:checked label:before {
  background-color: #c29958;
  border-color: #c29958;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  width: 15px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

/*-------- Custom Checkbox Style end --------*/
/*------- Custom Radio Button Style start -------*/
.custom-radio label:before,
.custom-radio .custom-control-label:before {
  border-radius: 50%;
  height: 14px;
  width: 14px;
}

.custom-radio label::after,
.custom-radio .custom-control-label::after {
  background-size: 10px 10px;
  left: -22px;
  top: 2px;
  height: 10px;
  width: 10px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

/*------- Custom Radio Button Style end -------*/
.bg-gray {
  background-color: #f4f4f4;
}

/*------ twitter feed start ------*/
.twitter-feed-content {
  padding: 32px 0;
  border-bottom: 1px solid #efefef;
}

.twitter-feed-content p {
  color: #888;
}

.twitter-feed-content p a {
  color: #c29958;
}

.twitter-feed-content p a:hover {
  text-decoration: underline;
}

/*------ twitter feed end ------*/
/*----- product tab menu start -----*/
.product-tab-menu {
  margin-top: -6px;
  margin-bottom: 35px;
}

.product-tab-menu ul li {
  margin-right: 35px;
  text-transform: uppercase ;
}

@media only screen and (max-width: 479.98px) {
  .product-tab-menu ul li {
    margin-right: 15px;
  }
}

.product-tab-menu ul li:last-child {
  margin-right: 0;
}

.product-tab-menu ul li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #777777;
}

.product-tab-menu ul li a:hover,
.product-tab-menu ul li a.active {
  color: #222222;
}

.tab-content .tab-pane.show.active .product-item {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

/*----- product tab menu start -----*/
/*----- brand logo style start -----*/
.brand-logo-carousel {
  padding: 30px 0;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.brand-item img {
  margin: auto;
}

/*----- brand logo style end -----*/
/*----- hot deals item start -----*/
.hot-deals-item {
  border: 1px solid #c29958;
  border-radius: 5px;
}

.hot-deals-item .product-caption {
  padding: 20px;
}

@media only screen and (max-width: 767.98px) {
  .hot-deals-item .product-caption {
    padding: 20px 10px;
  }
}

.hot-deals-item .manufacturer-name {
  padding-bottom: 5px;
}

.hot-deals-item .product-name {
  font-size: 20px;
  padding-bottom: 30px;
}

.hot-deals-item .price-box {
  font-size: 21px;
  padding-top: 8px;
}

.progress {
  height: 15px;
  border-radius: 50px;
}

.progress-quantity {
  position: relative;
  margin-top: 52px;
}

.progress-bar {
  background-color: #c29958 !important;
  width: 60%;
}

.progress-bar:nth-child(2) {
  width: 70%;
}

.progress-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: calc(-100% - 8px);
  left: 0;
  width: 100%;
  line-height: 1;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.product-sold,
.product-available {
  font-size: 14px;
  color: #777777;
}

.product-sold span,
.product-available span {
  color: #c29958;
  font-weight: 700;
}

.product-countdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 30px 0;
}

.product-countdown .single-countdown {
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 45px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #eaeaea;
}

.product-countdown .single-countdown__time {
  font-size: 16px;
  display: inline-block;
  color: #222222;
}

.product-countdown .single-countdown__text {
  font-size: 11px;
  display: block;
  color: #222222;
  line-height: 1;
  margin-top: 5px;
  text-transform: uppercase;
}

.product-countdown .single-countdown:last-child {
  margin-right: 0;
}

.product-countdown--style-two {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.product-details-des .product-countdown {
  margin: 20px 0 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

/*----- hot deals item start -----*/
/*------ Instagram Feed Area Start ------*/
.instagram-item {
  position: relative;
  z-index: 1;
}

.instagram-item:before {
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 100%;
  width: 100%;
}

.instagram-item .instagram-hvr-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.instagram-item .instagram-hvr-content span {
  color: #fff;
  margin-right: 20px;
}

.instagram-item .instagram-hvr-content span:last-child {
  margin-right: 0;
}

.instagram-item .instagram-hvr-content span i.fa {
  margin-right: 5px;
}

.instagram-item:hover:before {
  opacity: 1;
  visibility: visible;
}

.instagram-item:hover .instagram-hvr-content {
  opacity: 1;
  visibility: visible;
}

/*------ Instagram Feed Area End ------*/
.policy-list:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767.98px) {
  .policy-list:not(:last-child) {
    margin-bottom: 25px;
  }
}

.policy-list a {
  color: #c29958;
}

.policy-title {
  margin-bottom: 10px;
}

/*------- common style css end -------*/
/*------- product item start -------*/
.product-item {
  overflow: hidden;
  margin-bottom: 15px;
}

.product-item .color-categories {
  padding: 13px 0 6px;
}

.product-item:hover .product-thumb .sec-img,
.product-list-item:hover .product-thumb .sec-img {
  opacity: 1;
  visibility: visible;
}

/* .product-item:hover .product-thumb .pri-img, .product-list-item:hover .product-thumb .pri-img {
  opacity: 0;
  visibility: hidden;
} */
.product-item:hover .button-group a,
.product-list-item:hover .button-group a {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.product-item:hover .cart-hover,
.product-list-item:hover .cart-hover {
  bottom: 15px;
  opacity: 1;
  visibility: visible;
}

.product-thumb {
  position: relative;
}

.product-thumb img {
  width: 95%;
  margin: auto;
}


.category-image2{
  width: 100%;
}


.product-thumb .sec-img {
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.product-caption {
  padding: 10px 0;
}

.product-caption .product-name {
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 8px;
}

.product-caption .product-name a {
  color: #222222;
  text-transform: capitalize;
}

.product-caption .product-name a:hover {
  color: #bc1823;
}

.manufacturer-name {
  font-size: 18px;
  padding-bottom: 5px;
  line-height: 1;
}

.manufacturer-name a {
  font-size: 18px;
  line-height: 1;
  color: #777777;
  text-transform: capitalize;
}

.manufacturer-name a:hover {
  color: #bc1823;
}

.color-categories {
  line-height: 1;
}

.color-categories li {
  display: inline-block;
  padding: 1px;
  border-radius: 50%;
  border: 1px solid #efefef;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.color-categories li:hover {
  border-color: #c29958;
}

.color-categories li a {
  height: 12px;
  width: 12px;
  display: block;
  border-radius: 50%;
}

.c-lightblue {
  background-color: #b0c4de;
}

.c-darktan {
  background-color: #aa9e78;
}

.c-grey {
  background-color: #808080;
}

.c-brown {
  background-color: #964B00;
}

.button-group {
  right: 15px;
  top: 15px;
  position: absolute;
}

.button-group a {
  width: 38px;
  height: 38px;
  display: block;
  font-size: 18px;
  line-height: 42px;
  color: #222222;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: #fff;
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
}

.button-group a span {
  display: block;
}

.button-group a:hover {
  color: #c29958;
}

.cart-hover {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.product-label {
  background: #bc1823;
  border-radius: 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  left: 20px;
  line-height: 1;
  min-width: 46px;
  padding: 4px 12px;
  position: absolute;
  text-align: center;
  text-transform: capitalize;
  z-index: 3;
}

.product-label span {
  display: block;
  margin-top: -1px;
}

.product-label.discount {
  background-color: #222222;
}

.product-label:nth-child(1) {
  top: 20px;
}

.product-label:nth-child(2) {
  top: 48px;
}

.ratings {
  color: #f9bd22;
  font-size: 14px;
}

.ratings span {
  margin-right: 3px;
}

.price-box {
  font-size: 15px;
  line-height: 1;
  margin-bottom: 10px;
}

.price-regular {
  color: #bc1823;
  font-weight: 500;
}

.price-old {
  color: #999;
  padding-left: 5px;
  font-weight: 300;
}

/*------- product item end -------*/
/*-------- product list item start --------*/
.product-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767.98px) {
  .product-list-item {
    display: block !important;
  }
}

.product-list-item .product-thumb {
  max-width: 30%;
  -webkit-flex-basis: 30%;
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  height: 100%;
}

@media only screen and (max-width: 767.98px) {
  .product-list-item .product-thumb {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.product-content-list {
  padding-left: 20px;
  max-width: 70%;
  -webkit-flex-basis: 70%;
  -ms-flex-preferred-size: 70%;
  flex-basis: 70%;
}

@media only screen and (max-width: 767.98px) {
  .product-content-list {
    padding-left: 0;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-top: 30px;
  }
}

.product-content-list .manufacturer-name {
  padding-bottom: 10px;
}

.product-content-list .ratings {
  padding-bottom: 10px;
}

.product-content-list .product-name {
  padding: 10px 0 12px;
}

.product-content-list .product-name a {
  color: #222222;
  font-weight: 400;
}

.product-content-list .product-name a:hover {
  color: #c29958;
}

.product-content-list p {
  padding-top: 15px;
  margin-top: 22px;
  border-top: 1px solid #efefef;
}

/*-------- product list item end --------*/
/*------ group list item start ------*/
@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .group-product-banner {
    margin-bottom: 80px;
  }
}

.group-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.group-item-thumb {
  max-width: 80px;
  -webkit-flex-basis: 80px;
  -ms-flex-preferred-size: 80px;
  flex-basis: 80px;
}

.group-item-desc {
  position: relative;
  padding-left: 10px;
  max-width: calc(100% - 80px);
  -webkit-flex-basis: calc(100% - 80px);
  -ms-flex-preferred-size: calc(100% - 80px);
  flex-basis: calc(100% - 80px);
}

.group-item-desc .group-product-name {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  text-transform: capitalize;
  padding-bottom: 18px;
}

.group-item-desc .group-product-name a {
  color: #222222;
}

.group-item-desc .group-product-name a:hover {
  color: #c29958;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-group-wrapper {
    margin-bottom: 78px;
  }
}

@media only screen and (max-width: 767.98px) {
  .categories-group-wrapper {
    margin-bottom: 60px;
  }
}

.row [class*=col-]:last-child .categories-group-wrapper {
  margin-bottom: 0;
}

.group-list-item-wrapper .slick-slider .slick-slide>div {
  margin-bottom: 20px;
}

.group-list-item-wrapper .slick-slider .slick-slide>div:last-child {
  margin-bottom: 0;
}

/*------ group list item end ------*/
/*----- testimonial area start -----*/
.testimonial-thumb-wrapper {
  max-width: 290px;
  margin: auto;
}

.testimonial-thumb {
  cursor: pointer;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  width: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.slick-slide.slick-current .testimonial-thumb {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.testimonial-content-wrapper {
  max-width: 910px;
  margin: auto;
  margin-top: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-content-wrapper {
    max-width: 750px;
  }
}

.testimonial-content {
  padding: 0 15px;
  text-align: center;
}

.testimonial-content p {
  font-size: 15px;
}

.testimonial-content .ratings {
  margin-top: 30px;
  margin-bottom: 5px;
}

.testimonial-author {
  font-size: 17px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
}

/*----- testimonial area end -----*/
/*----- breadcrumb style css start -----*/
.breadcrumb-area {
  background: #da44444a !important;
}

.breadcrumb-wrap {
  padding: 15px 0;
  text-align: center;
}

.breadcrumb-wrap .breadcrumb {
  background: transparent;
  margin-bottom: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item a {
  color: #222222;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item a:hover {
  color: #bc1823;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item:before {
  color: #7e7e7e;
  content: "/";
  font-size: 12px;
  margin: 0 5px;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item:first-child::before {
  display: none;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item.active {
  color: #bc1823;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
}

/*----- breadcrumb style css end -----*/
/*----- shop Sidebar start -----*/
.sidebar-wrapper .sidebar-single:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767.98px) {
  .sidebar-wrapper {
    margin-top: 62px;
  }
}

.sidebar-single {
  margin-bottom: 35px;
}

.sidebar-single .sidebar-title {
  position: relative;
  line-height: 1;
  margin-top: -3px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.sidebar-single .sidebar-title:before {
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  content: " ";
  position: absolute;
  background-color: #efefef;
}

/*------ pricing filter slider start ------*/
.price-range-wrap {
  padding-top: 8px;
}

.price-range-wrap .price-range {
  border-radius: 0;
  margin-right: 13px;
  margin-bottom: 20px;
}

.price-range-wrap .price-range.ui-widget-content {
  border: none;
  background: #eeeeee;
  height: 6px;
  border-radius: 20px;
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-range {
  background-color: #c29958;
  border-radius: 0;
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-handle {
  border: none;
  background-color: #fff;
  height: 12px;
  width: 12px;
  outline: none;
  cursor: ew-resize;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.6);
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-handle:before {
  top: 50%;
  left: 50%;
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  background-color: #c29958;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.price-range-wrap .range-slider {
  color: #222222;
  margin-top: 30px;
}

.price-range-wrap .range-slider .price-input label {
  color: #222222;
  margin-bottom: 0;
}

.price-range-wrap .range-slider .price-input input {
  color: #555555;
  border: none;
  outline: none;
  max-width: 80px;
  pointer-events: none;
}

.price-range-wrap .range-slider button.filter-btn {
  border: none;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-transform: uppercase;
  padding: 4px 15px;
  border-radius: 3px;
  background-color: #ebebeb;
}

.price-range-wrap .range-slider button.filter-btn:hover {
  color: #fff;
  background-color: #c29958;
}

/*------ pricing filter slider end ------*/
.categories-list li {
  color: #555555;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.categories-list li:last-child {
  margin-bottom: 0;
}

.categories-list li:hover {
  color: #c29958;
}

/*------ shop categories area start ------*/
.shop-categories {
  margin-top: -4px;
}

.shop-categories li a {
  color: #555555;
  display: block;
  line-height: 1;
  padding: 10px 0;
  text-transform: capitalize;
}

.shop-categories li:first-child a {
  padding-top: 0;
}

.shop-categories li:last-child a {
  padding-bottom: 0;
}

.shop-categories li:hover a {
  color: #c29958;
}

/*------ shop categories area end ------*/
/*----- shop Sidebar end -----*/
/*------ pagination area style start ------*/
.paginatoin-area {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #efefef;
}

.paginatoin-area .pagination-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.paginatoin-area .pagination-box li {
  margin-right: 5px;
  display: inline-block;
}

.paginatoin-area .pagination-box li:last-child {
  margin-right: 0;
}

.paginatoin-area .pagination-box li a {
  color: #222222;
  height: 36px;
  width: 36px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  line-height: 36px;
  background-color: #f5f5f5;
  border-radius: 50%;
}

.paginatoin-area .pagination-box li a i {
  font-size: 30px;
  line-height: 36px;
}

.paginatoin-area .pagination-box li a:hover {
  color: #fff;
  border-color: #bc1823;
  background-color: #bc1823;
}

.paginatoin-area .pagination-box li.active a {
  color: #fff;
  background-color: #bc1823;
}

/*------ pagination area style end ------*/
/*------- blog item start -------*/
.blog-post-item:hover .blog-thumb img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.7;
}

.blog-thumb {
  overflow: hidden;
}

.blog-thumb img {
  width: 100%;
}

.blog-content {
  padding-top: 15px;
}

.blog-title {
  line-height: 1.3;
  text-transform: capitalize;
}

.blog-title a {
  color: #222222;
  display: block;
}

.blog-title a:hover {
  color: #c29958;
}

.blog-meta {
  padding-bottom: 15px;
}

.blog-meta p {
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  color: #777;
}

.blog-meta p a {
  color: #c29958;
}

/*------- blog item end -------*/
/*------ blog list item start ------*/
.blog-list-inner .blog-post-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767.98px) {
  .blog-list-inner .blog-post-item {
    display: block;
  }
}

.blog-list-inner .blog-post-item .blog-thumb {
  width: 40%;
}

@media only screen and (max-width: 767.98px) {
  .blog-list-inner .blog-post-item .blog-thumb {
    width: 100%;
  }
}

.blog-list-inner .blog-post-item .blog-content {
  padding-top: 0;
  padding-left: 20px;
  width: 60%;
}

@media only screen and (max-width: 767.98px) {
  .blog-list-inner .blog-post-item .blog-content {
    width: 100%;
    padding-left: 0;
    padding-top: 30px;
  }
}

.blog-list-inner .blog-post-item .blog-content .blog-title {
  margin-top: -5px;
}

.blog-list-inner .blog-post-item .blog-content .blog-meta {
  padding-top: 15px;
}

.blog-list-inner .blog-post-item .blog-content .blog-read-more {
  color: #222222;
  display: inline-block;
  margin-top: 10px;
}

.blog-list-inner .blog-post-item .blog-content .blog-read-more:hover {
  color: #c29958;
  text-decoration: underline;
}

/*------ blog list item end ------*/
/*----- blog sidebar start -------*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-sidebar-wrapper {
    margin-bottom: -5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-sidebar-wrapper {
    margin-top: 77px;
    margin-bottom: -5px;
  }
}

@media only screen and (max-width: 767.98px) {
  .blog-sidebar-wrapper {
    margin-top: 60px;
    margin-bottom: -5px;
  }
}

.blog-sidebar-wrapper .blog-sidebar:last-child {
  margin-bottom: 0;
}

.blog-sidebar {
  margin-bottom: 30px;
}

.blog-sidebar .title {
  line-height: 1;
  margin-top: -2px;
  margin-bottom: 40px;
  position: relative;
  text-transform: capitalize;
}

.blog-sidebar .title:before {
  width: 100%;
  height: 1px;
  bottom: -10px;
  left: 0;
  background-color: #efefef;
  content: "";
  position: absolute;
}

.blog-sidebar .search-field {
  width: calc(100% - 50px);
  border: 1px solid #ccc;
  padding: 0 10px;
  color: #555555;
  font-size: 14px;
  height: 50px;
  float: left;
  text-transform: capitalize;
}

.blog-sidebar .search-btn {
  width: 50px;
  height: 50px;
  margin-left: -4px;
  text-align: center;
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.blog-sidebar .search-btn i {
  font-size: 20px;
  line-height: 50px;
  color: #222222;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.blog-sidebar .search-btn:hover {
  background-color: #c29958;
  border-color: #c29958;
}

.blog-sidebar .search-btn:hover i {
  color: #fff;
}

.blog-sidebar .blog-archive {
  margin-top: -10px;
}

.blog-sidebar .blog-archive li a {
  color: #555555;
  font-size: 14px;
  line-height: 1;
  text-transform: capitalize;
  padding: 13px 0;
  display: inline-block;
}

.blog-sidebar .blog-archive li a:hover {
  color: #c29958;
}

.blog-sidebar .blog-archive li:last-child a {
  padding-bottom: 0;
}

.blog-sidebar .blog-archive li:first-child a {
  padding-top: 0;
}

.blog-sidebar .blog-tags li {
  display: inline-block;
  margin-bottom: 5px;
}

.blog-sidebar .blog-tags li a {
  color: #222222;
  font-size: 14px;
  border: 1px solid #ccc;
  padding: 6px 15px 4px;
  display: inline-block;
  text-transform: capitalize;
}

.blog-sidebar .blog-tags li a:hover {
  color: #fff;
  background-color: #c29958;
  border-color: #c29958;
}

.blog-category {
  margin-top: -10px;
}

.recent-post .recent-post-item:last-child {
  margin-bottom: 0;
}

.recent-post-item {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.recent-post-item .product-thumb {
  max-width: 70px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 70px;
  -ms-flex: 1 0 70px;
  flex: 1 0 70px;
}

.recent-post-item .recent-post-description {
  padding-left: 10px;
}

.recent-post-item .recent-post-description h6 {
  font-size: 14px;
  padding-bottom: 5px;
  text-transform: capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .recent-post-item .recent-post-description h6 {
    font-size: 13px;
    padding-bottom: 5px;
  }
}

.recent-post-item .recent-post-description h6 a {
  color: #222222;
}

.recent-post-item .recent-post-description h6 a:hover {
  color: #c29958;
}

.recent-post-item .recent-post-description p {
  text-transform: capitalize;
}

/*----- blog sidebar end -------*/
.entry-summary {
  padding-top: 25px;
}

blockquote {
  border-left: 5px solid #c29958;
  margin: 25px 32px 25px 30px;
  background-color: #f8f8f8;
  padding: 15px;
}

.blog-details-post .blog-content .blog-title {
  padding-top: 10px;
}

@media only screen and (max-width: 767.98px) {
  .blog-details-post .blog-content .blog-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 479.98px) {
  .blog-details-post .blog-content .blog-title {
    font-size: 18px;
  }
}

.blog-details-post .blog-content .blog-meta {
  padding-top: 15px;
  padding-bottom: 0;
}

.blog-details-post:hover .blog-thumb img {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

/*------- blog main content wrapper start --------*/
.blog-post-item .tag-line {
  padding: 10px 0;
  margin-top: 20px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.blog-post-item .tag-line h6 {
  font-size: 14px;
  display: inline-block;
  padding-right: 5px;
}

.blog-post-item .tag-line a {
  font-size: 13px;
  color: #222222;
}

.blog-post-item .tag-line a:hover {
  color: #c29958;
}

.blog-share-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .blog-share-link {
    padding-top: 15px;
  }
}

.blog-share-link h6 {
  font-size: 14px;
  padding-right: 15px;
}

.blog-share-link .blog-social-icon a {
  width: 36px;
  height: 36px;
  font-size: 15px;
  line-height: 36px;
  text-align: center;
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  margin-right: 5px;
}

.blog-share-link .blog-social-icon a.facebook {
  background-color: #3B5999;
}

.blog-share-link .blog-social-icon a.twitter {
  background-color: #1DA1F2;
}

.blog-share-link .blog-social-icon a.pinterest {
  background-color: #CB2028;
}

.blog-share-link .blog-social-icon a.google {
  background-color: #fe6d4c;
}

.blog-share-link .blog-social-icon a:hover.facebook {
  background-color: #2d4474;
}

.blog-share-link .blog-social-icon a:hover.twitter {
  background-color: #0c85d0;
}

.blog-share-link .blog-social-icon a:hover.pinterest {
  background-color: #9f191f;
}

.blog-share-link .blog-social-icon a:hover.google {
  background-color: #fe4419;
}

@media only screen and (max-width: 479.98px) {
  .blog-share-link .blog-social-icon a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    margin-right: 0;
  }
}

/*--- blog main content wrapper end ---*/
/*--- blog comment section start ---*/
.comment-section {
  margin-top: -3px;
}

.comment-section h5 {
  line-height: 1;
  padding-bottom: 15px;
}

.comment-section ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  margin-bottom: 30px;
  border: 1px solid #efefef;
}

.comment-section ul li:last-child {
  margin-bottom: 5px;
}

@media only screen and (max-width: 479.98px) {
  .comment-section ul li {
    display: block;
  }
}

.comment-section ul li .author-avatar {
  -webkit-flex-basis: 66px;
  -ms-flex-preferred-size: 66px;
  flex-basis: 66px;
  max-height: 62px;
  margin-right: 10px;
}

@media only screen and (max-width: 479.98px) {
  .comment-section ul li .author-avatar {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 66px;
    height: 62px;
  }
}

.comment-section ul li.comment-children {
  margin-left: 40px;
}

@media only screen and (max-width: 479.98px) {
  .comment-section ul li.comment-children {
    margin-left: 20px;
  }
}

.comment-section ul li .comment-body {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.comment-section ul li .comment-body h5 {
  font-size: 14px;
  padding-bottom: 5px;
}

.comment-section ul li .comment-body .comment-post-date {
  color: #555555;
  padding-bottom: 10px;
}

.comment-section ul li .comment-body .reply-btn {
  float: right;
}

.comment-section ul li .comment-body .reply-btn a {
  color: #222222;
  font-size: 12px;
  display: inline-block;
  padding: 4px 15px;
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid #efefef;
}

.comment-section ul li .comment-body .reply-btn a:hover {
  color: #fff !important;
  border-color: #c29958;
  background-color: #c29958;
}

/*--- blog comment section end ---*/
/*------ blog comment box start -----*/
.blog-comment-wrapper {
  margin-top: -10px;
}

.blog-comment-wrapper h3 {
  color: #222222;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  padding-bottom: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid #f0f0f0;
}

.blog-comment-wrapper p {
  margin-bottom: 10px;
  padding-top: 10px;
}

.blog-comment-wrapper .comment-post-box label {
  color: #222222;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  text-transform: capitalize;
}

.blog-comment-wrapper .comment-post-box .coment-field {
  background: #f8f8f8;
  border: none;
  color: #555555;
  padding: 8px 10px;
  width: 100%;
}

@media only screen and (max-width: 767.98px) {
  .blog-comment-wrapper .comment-post-box .coment-field {
    margin-bottom: 20px;
  }
}

.blog-comment-wrapper .comment-post-box textarea {
  height: 130px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  background: #f8f8f8;
  border: none;
}

.blog-comment-wrapper .comment-post-box .coment-btn {
  margin-top: 30px;
}

@media only screen and (max-width: 767.98px) {
  .blog-comment-wrapper .comment-post-box .coment-btn {
    margin-top: 10px;
  }
}

/*------- blog comment box end --------*/
/*------- about us area start -------*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-thumb {
    text-align: center;
  }
}

@media only screen and (max-width: 767.98px) {
  .about-thumb {
    text-align: center;
  }

  .about-thumb img {
    width: 100%;
  }
}

.about-title {
  font-size: 48px;
  color: #bc1823;
  display: block;
  line-height: 1.1;
  margin-bottom: 38px;
}

@media only screen and (max-width: 767.98px) {
  .about-title {
    font-size: 26px;
    color: #bc1823;
    margin-bottom: 25px;
  }
}

.about-sub-title {
  line-height: 1.5;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767.98px) {
  .about-sub-title {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .about-content {
    text-align: justify;
    padding: 52px 30px 0;
    margin-bottom: -6px;
  }
}

@media only screen and (max-width: 479.98px) {
  .about-content {
    padding: 52px 0 0 0;
  }
}

.about-content p {
  font-size: 15px;
  line-height: 1.8;
  padding-bottom: 12px;
  text-align: justify;
}

.about-content p:last-child {
  padding-bottom: 0;
}

/*------- about us area end -------*/
/*-------- choosing area start --------*/
.choosing-area {
  margin-bottom: -4px;
}

@media only screen and (max-width: 767.98px) {
  .single-choose-item {
    padding: 0 20px;
  }
}

.single-choose-item i {
  color: #969ca7;
  font-size: 44px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.single-choose-item h4 {
  color: #222222;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0 10px;
  text-transform: capitalize;
}

.single-choose-item:hover i {
  color: #c29958;
}

/*-------- choosing area end --------*/
/*------- team area start -------*/
.team-member {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.team-member .team-thumb {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.team-member .team-thumb img {
  width: 100%;
}

.team-member .team-thumb .team-social {
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  position: absolute;
  background-color: #fff;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
}

.team-member .team-thumb .team-social a {
  color: #222222;
  width: 30px;
  height: 30px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-right: 5px;
}

.team-member .team-thumb .team-social a:hover {
  color: #fff;
  background-color: #c29958;
  border-color: #c29958;
}

.team-member .team-content .team-member-name {
  padding-top: 15px;
}

.team-member .team-content p {
  font-size: 14px;
  line-height: 1;
  padding-top: 5px;
  color: #c29958;
}

.team-member:hover .team-social {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.team-member:hover .team-thumb img {
  opacity: 0.5;
}

/*------- team area end -------*/
/*------ shop page style start ------*/
.shop-top-bar {
  margin-bottom: 30px;
}

.top-bar-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 767.98px) {
  .top-bar-left {
    padding-top: 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.top-bar-left .product-view-mode {
  margin-right: 60px;
}

@media only screen and (max-width: 479.98px) {
  .top-bar-left .product-view-mode {
    margin-right: 0;
  }
}

.top-bar-left .product-view-mode a {
  color: #b6b6b6;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.top-bar-left .product-view-mode a i {
  font-size: 20px;
  margin-top: 6px;
}

.top-bar-left .product-view-mode a:hover {
  color: #c29958;
}

.top-bar-left .product-view-mode a.active {
  color: #c29958;
}

.top-bar-left .product-amount h4 {
  color: #bc1823;
  text-transform: capitalize;
}

.top-bar-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (max-width: 767.98px) {
  .top-bar-right {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.top-bar-right .product-short {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
}

.top-bar-right .product-short p {
  margin-right: 10px;
}

.nice-select {
  height: 36px;
  line-height: 34px;
  width: 200px;
  padding: 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nice-select {
    width: 170px;
  }
}

@media only screen and (max-width: 479.98px) {
  .nice-select {
    width: 190px;
  }
}

.nice-select.open {
  border-color: #c29958;
}

.nice-select .list {
  right: 0;
  left: auto;
  width: 100%;
}

.nice-select .option {
  font-size: 13px;
  line-height: 34px;
  min-height: 34px;
  text-transform: capitalize;
}

.nice-select:after {
  height: 6px;
  width: 6px;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}

/*----- shop main wrapper end -----*/
/*----- product change view start -----*/
.shop-product-wrap.list-view .product-item {
  display: none;
}

.shop-product-wrap.grid-view .product-item {
  display: block;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  padding: 0;
}

.shop-product-wrap.grid-view .product-list-item {
  display: none !important;
}

.shop-product-wrap.list-view .product-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.shop-product-wrap.row.list-view .col-md-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.shop-product-wrap [class*=col-] {
  margin-bottom: 30px;
}

/*----- product change view end -----*/
/*------ shop page style start ------*/
/*----- product details slider start ----*/
.pro-large-img {
  position: relative;
  cursor: pointer;
}

.pro-large-img img {
  width: 100%;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.pro-nav-thumb {
  cursor: pointer;
}

.pro-nav {
  margin-top: 20px;
}

/*----- product details slider end ----*/
/*----- product details content start ----*/
.option-title {
  line-height: 1;
  font-weight: 400;
  margin-right: 10px;
  text-transform: capitalize;
}

.product-details-des .product-name {
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 575.98px) {
  .product-details-des .product-name {
    font-size: 17px;
  }
}

.product-details-des .pro-review {
  padding-left: 10px;
}

.product-details-des .pro-review span {
  font-size: 14px;
  line-height: 1;
  color: #555555;
}

.product-details-des .price-box {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-details-des .customer-rev {
  padding-top: 10px;
}

.product-details-des .customer-rev a {
  color: #222222;
  font-size: 13px;
  text-transform: capitalize;
}

.product-details-des .customer-rev a:hover {
  color: #c29958;
}

.product-details-des .offer-text {
  font-weight: 400;
}

.product-details-des .quantity-cart-box {
  margin-bottom: 20px;
}

.product-details-des .quantity {
  margin-right: 15px;
}

.product-details-des .quantity .pro-qty {
  width: 90px;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 15px;
  border-radius: 40px;
  float: left;
}

.product-details-des .quantity .pro-qty .qtybtn {
  width: 15px;
  display: block;
  float: left;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  color: #555555;
}

.product-details-des .quantity .pro-qty input {
  width: 28px;
  float: left;
  border: none;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: center;
  background-color: transparent;
}

.product-details-des .pro-desc {
  /* margin-bottom: 20px; */
  text-align: justify;
  font-size: 18px;
}

.product-details-des .availability {
  margin-bottom: 15px;
}

.product-details-des .availability i {
  color: #81ca33;
}

.product-details-des .availability span {
  color: #555555;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  padding-left: 3px;
  text-transform: uppercase;
}

.product-details-des .useful-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.product-details-des .useful-links a {
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  margin-right: 20px;
  text-transform: capitalize;
}

.product-details-des .useful-links a:hover {
  color: #c29958;
}

.product-details-des .useful-links a i {
  font-size: 20px;
  padding-right: 5px;
  vertical-align: middle;
}

.product-details-des .like-icon {
  padding-top: 20px;
  border-top: 1px solid #efefef;
}

.product-details-des .like-icon a {
  color: #fff;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  padding: 0 8px;
  margin-right: 6px;
  border-radius: 3px;
  text-transform: capitalize;
}

@media only screen and (max-width: 479.98px) {
  .product-details-des .like-icon a {
    margin-right: 0;
  }
}

.product-details-des .like-icon a i {
  padding-right: 5px;
}

.product-details-des .like-icon a.facebook {
  background-color: #3B5999;
}

.product-details-des .like-icon a.twitter {
  background-color: #1DA1F2;
}

.product-details-des .like-icon a.pinterest {
  background-color: #CB2028;
}

.product-details-des .like-icon a.google {
  background-color: #fe6d4c;
}

.product-details-des .like-icon a:hover.facebook {
  background-color: #2d4474;
}

.product-details-des .like-icon a:hover.twitter {
  background-color: #0c85d0;
}

.product-details-des .like-icon a:hover.pinterest {
  background-color: #9f191f;
}

.product-details-des .like-icon a:hover.google {
  background-color: #fe4419;
}

.product-details-des .share-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details-des .share-icon a {
  color: #555555;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  padding: 0 8px;
  margin-right: 5px;
}

@media only screen and (max-width: 479.98px) {
  .product-details-des .share-icon a {
    padding: 0 5px;
  }
}

.product-details-des .share-icon a:hover {
  color: #c29958;
}

.product-details-des .color-option {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 18px;
}

.product-details-des .pro-size {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 18px;
}

.product-details-des .pro-size .nice-select {
  width: 100px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
}

.product-details-des .group-product-table {
  margin-top: 30px;
  margin-bottom: 20px;
  display: block;
}

.product-details-des .group-product-table.table-bordered {
  border: none;
}

.product-details-des .group-product-table td {
  width: 33.33%;
  padding: 8px;
  vertical-align: middle;
}

.product-details-des .group-product-table td a {
  color: #555555;
  text-transform: capitalize;
}

.product-details-des .group-product-table td a:hover {
  color: #c29958;
}

.product-details-des .group-product-table td .pro-qty {
  width: 90px;
  height: 35px;
  border: 1px solid #ddd;
  padding: 0 15px;
  float: left;
}

.product-details-des .group-product-table td .pro-qty .qtybtn {
  width: 15px;
  display: block;
  float: left;
  line-height: 31px;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  color: #555555;
}

.product-details-des .group-product-table td .pro-qty input {
  width: 28px;
  float: left;
  border: none;
  height: 33px;
  line-height: 33px;
  padding: 0;
  text-align: center;
  background-color: transparent;
}

/*------ countdown style end ------*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-des {
    margin-top: 76px;
  }
}

@media only screen and (max-width: 767.98px) {
  .product-details-des {
    margin-top: 58px;
  }
}

.product-details-des.quick-details {
  margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-des.quick-details {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .product-details-des.quick-details {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-des.quick-details .product-name {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767.98px) {
  .product-details-des.quick-details .product-name {
    font-size: 18px;
  }
}

.product-details-des.box-layout {
  padding-top: 47px;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .product-details-des.box-layout {
    margin-top: 0;
  }
}

.product-details-des.box-layout p {
  max-width: 80%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .product-details-des.box-layout p {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .product-details-des.sidebar-sticky {
    margin-top: 0;
  }
}

.product-details-des.quick-des p {
  padding-top: 0;
}

/*----- product details content end ----*/
/*----- reviews area start -----*/
.product-review-info .nav.review-tab li a {
  color: #fff;
  display: block;
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  padding: 8px 10px;
  border: 1px solid #c29958;
  border-bottom: none;
  background-color: #c29958;
}

.product-review-info .nav.review-tab li a:hover,
.product-review-info .nav.review-tab li a.active {
  color: #555555;
  background-color: #fff;
  border-color: #ddd;
}

@media only screen and (max-width: 479.98px) {
  .product-review-info .nav.review-tab li a {
    font-size: 14px;
  }
}

.product-review-info .tab-content.reviews-tab {
  border: 1px solid #efefef;
  padding: 15px 20px 20px;
}

.product-review-info .tab-content.reviews-tab .table {
  margin-bottom: 0;
}

.product-review-info .tab-content.reviews-tab .table tr {
  text-transform: capitalize;
}

.product-review-info .tab-content .review-description {
  padding-top: 25px;
  padding-bottom: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-review-info .tab-content .review-description {
    padding: 15px 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .product-review-info .tab-content .review-description {
    display: block;
  }
}

.product-review-info .tab-content .review-description .tab-thumb {
  -webkit-flex-basis: 300px;
  -ms-flex-preferred-size: 300px;
  flex-basis: 300px;
}

@media only screen and (max-width: 767.98px) {
  .product-review-info .tab-content .review-description .tab-thumb {
    -webkit-flex-basis: 220px;
    -ms-flex-preferred-size: 220px;
    flex-basis: 220px;
  }
}

.product-review-info .tab-content .review-description .tab-des {
  padding-left: 40px;
  -webkit-flex-basis: calc(100% - 300px);
  -ms-flex-preferred-size: calc(100% - 300px);
  flex-basis: calc(100% - 300px);
}

@media only screen and (max-width: 767.98px) {
  .product-review-info .tab-content .review-description .tab-des {
    padding-left: 14px;
    -webkit-flex-basis: calc(100% - 220px);
    -ms-flex-preferred-size: calc(100% - 220px);
    flex-basis: calc(100% - 220px);
  }
}

.product-review-info .tab-content .review-description .tab-des h3 {
  color: #c29958;
  font-size: 20px;
  font-weight: 22px;
  font-weight: 700;
  text-transform: capitalize;
  padding-bottom: 10px;
}

.product-review-info .tab-content .review-description .tab-des ul li {
  font-size: 13px;
  list-style: inherit;
}

.review-form h5 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;
  text-transform: capitalize;
}

@media only screen and (max-width: 575.98px) {
  .review-form h5 {
    font-size: 16px;
  }
}

.total-reviews {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 50px;
}

@media only screen and (max-width: 479.98px) {
  .total-reviews {
    display: block;
  }
}

.total-reviews .rev-avatar {
  -webkit-flex-basis: 60px;
  -ms-flex-preferred-size: 60px;
  flex-basis: 60px;
  max-height: 60px;
}

@media only screen and (max-width: 479.98px) {
  .total-reviews .rev-avatar {
    margin-bottom: 20px;
  }
}

.total-reviews .review-box {
  margin-left: 10px;
  border: 1px solid #efefef;
  padding: 10px;
  -webkit-flex-basis: calc(100% - 70px);
  -ms-flex-preferred-size: calc(100% - 70px);
  flex-basis: calc(100% - 70px);
}

@media only screen and (max-width: 479.98px) {
  .total-reviews .review-box {
    margin-left: 0;
  }
}

.total-reviews .review-box .post-author {
  padding-bottom: 10px;
}

.total-reviews .review-box .post-author p {
  font-size: 12px;
  font-style: italic;
  text-transform: capitalize;
}

.total-reviews .review-box .post-author p span {
  font-size: 13px;
}

.total-reviews .review-box p {
  font-size: 14px;
  font-style: italic;
}

/*----- reviews area end -----*/
/* Checkout Login Coupon Accordion Start */
.checkout-page-wrapper {
  margin-bottom: -8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .checkout-page-wrapper {
    margin-bottom: 0;
  }
}

.checkoutaccordion {
  margin-bottom: 46px;
}

.checkoutaccordion h6 {
  background-color: #f7f7f7;
  border-top: 3px solid #c29958;
  font-size: 14px;
  padding: 15px 20px;
  position: relative;
  text-transform: capitalize;
}

.checkoutaccordion h6 span {
  color: #c29958;
  cursor: pointer;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  margin-left: 10px;
}

@media only screen and (max-width: 479.98px) {
  .checkoutaccordion h6 span {
    display: block;
    padding-top: 5px;
    margin-left: 0;
  }
}

.checkoutaccordion h6 span:hover {
  color: #222222;
}

@media only screen and (max-width: 479.98px) {
  .checkoutaccordion h6 {
    font-size: 14px;
  }
}

.checkoutaccordion .card {
  border: none;
  padding: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 30px;
}

.checkoutaccordion .card:last-child {
  margin-bottom: 0;
}

.checkoutaccordion .card .card-body {
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 20px;
}

.checkoutaccordion .card .card-body .cart-update-option {
  border: none;
  padding: 0;
}

.checkoutaccordion .card .card-body .cart-update-option .apply-coupon-wrapper input {
  padding: 12px 10px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  margin-right: 15px;
}

@media only screen and (max-width: 479.98px) {
  .checkoutaccordion .card .card-body .cart-update-option .apply-coupon-wrapper input {
    margin-bottom: 15px;
  }
}

.checkout-box-wrap .custom-control {
  padding-left: 0;
  margin-left: 1.5rem;
}

.checkout-billing-details-wrap .billing-form-wrap {
  margin-top: -20px;
}

.checkout-title {
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767.98px) {
  .checkout-title {
    margin-bottom: 26px;
  }
}

.forget-pwd {
  color: #c29958;
  font-size: 12px;
  margin-top: -6px;
}

.single-form-row {
  margin-top: 15px;
  display: none;
}

.single-form-row p {
  margin: 0;
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-summary-details {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 767.98px) {
  .order-summary-details {
    margin-top: 52px;
  }
}

.order-summary-table {
  background-color: #f7f7f7;
  margin-top: 34px;
}

.order-summary-table .table,
.order-summary-table table {
  color: #222222;
  margin-bottom: 0;
  font-size: 15px;
  white-space: nowrap;
}

.order-summary-table .table tr td,
.order-summary-table .table tr th,
.order-summary-table table tr td,
.order-summary-table table tr th {
  font-weight: 400;
  vertical-align: middle;
  padding: 15px 10px;
  border-width: 1px;
}

.order-summary-table .table tr td a,
.order-summary-table .table tr th a,
.order-summary-table table tr td a,
.order-summary-table table tr th a {
  color: #222222;
  font-weight: 400;
}

.order-summary-table .table tr td strong,
.order-summary-table .table tr th strong,
.order-summary-table table tr td strong,
.order-summary-table table tr th strong {
  font-weight: 400;
}

.order-summary-table .shipping-type {
  text-align: left;
}

.order-summary-table .shipping-type li {
  margin-bottom: 5px;
}

.order-summary-table .shipping-type li:last-child {
  margin-bottom: 0;
}

.order-summary-table .table> :not(:last-child)> :last-child>* {
  border-bottom-color: inherit;
}

.order-payment-method {
  background-color: #f7f7f7;
  padding: 40px 20px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .order-payment-method {
    padding: 32px 20px 36px;
  }
}

.single-payment-method {
  margin-bottom: 20px;
}

.single-payment-method:last-child {
  margin-bottom: 0;
}

.single-payment-method:first-child .payment-method-details {
  display: block;
}

.single-payment-method label,
.single-payment-method .custom-control-label {
  font-weight: 700;
}

.single-payment-method .paypal-card {
  max-width: 150px;
  height: 50px;
  margin-top: 10px;
  display: block;
}

.single-payment-method .payment-method-details {
  background-color: #f1f1f1;
  color: #222222;
  font-weight: 400;
  font-size: 13px;
  padding: 10px;
  position: relative;
  margin-top: 20px;
  display: none;
}

.single-payment-method .payment-method-details:after {
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f1f1f1;
  bottom: 100%;
}

.single-payment-method .payment-method-details * {
  margin: 0;
}

.summary-footer-area .btn-brand {
  margin-top: 40px;
}

/*------- input box style css start -------*/
.single-input-item {
  margin-top: 20px;
}

.single-input-item label {
  color: #222222;
  text-transform: capitalize;
  font-size: 14px;
}

.single-input-item label.required:after {
  content: "*";
  color: red;
  font-size: 14px;
  margin-left: 3px;
  margin-top: 5px;
}

.single-input-item input,
.single-input-item textarea {
  color: #555555;
  border: 1px solid #ccc;
  padding: 12px 10px;
  width: 100%;
  font-size: 14px;
  background: #f7f7f7;
}

.single-input-item input:active,
.single-input-item input:focus,
.single-input-item textarea:active,
.single-input-item textarea:focus {
  border-color: #c29958;
  background-color: #fff;
}

.single-input-item .nice-select {
  width: 100%;
  border-radius: 0;
  height: 48px;
  border-color: #ccc;
  background-color: #f7f7f7;
}

.single-input-item .nice-select .current {
  color: #555555;
}

.single-input-item .nice-select .list {
  max-height: 200px;
  overflow: auto;
  width: 100%;
}

/*------- input box style css end -------*/
/*--------- Cart Page Wrapper Start ---------*/
.cart-table .table {
  margin: 0;
  white-space: nowrap;
}

.cart-table .table thead {
  background-color: #c29958;
}

.cart-table .table thead tr th {
  border-color: #c29958;
  border-bottom: 0 solid transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 20px;
  text-align: center;
  text-transform: capitalize;
}

.cart-table .table tbody tr td {
  border-color: #ccc;
  text-align: center;
  padding: 10px 20px;
  vertical-align: middle;
  font-weight: 500;
  color: #222222;
}

.cart-table .table tbody tr td a {
  color: #222222;
  font-weight: 400;
  text-transform: capitalize;
}

.cart-table .table tbody tr td a:hover {
  color: #c29958;
}

.cart-table .table tbody tr td a.btn {
  color: #fff;
}

.cart-table .table tbody tr td a.btn:hover {
  color: #fff;
}

.cart-table .table tbody tr td a.check-btn {
  color: #fff;
}

.cart-table .table tr .pro-thumbnail,
.cart-table .table tr .pro-price,
.cart-table .table tr .pro-quantity,
.cart-table .table tr .pro-subtotal,
.cart-table .table tr .pro-remove {
  width: 140px;
}

.cart-table .table tr .pro-qty {
  width: 90px;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 15px;
  float: left;
}

.cart-table .table tr .pro-qty .qtybtn {
  width: 15px;
  display: block;
  float: left;
  line-height: 38px;
  cursor: pointer;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  color: #555555;
}

.cart-table .table tr .pro-qty input {
  width: 28px;
  float: left;
  border: none;
  height: 40px;
  line-height: 34px;
  padding: 0;
  text-align: center;
  background-color: transparent;
}

.cart-update-option {
  border: 1px solid #ccc;
  margin-top: 15px;
  padding: 15px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (min-width: 1200px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-update-option {
    margin-top: 0;
    border-top: 0 solid transparent;
  }
}

.cart-update-option .apply-coupon-wrapper {
  width: 55%;
}

@media only screen and (max-width: 767.98px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-update-option .apply-coupon-wrapper {
    width: auto;
  }
}

.cart-update-option .apply-coupon-wrapper form {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
}

.cart-update-option .apply-coupon-wrapper form input {
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 9px 10px;
  outline: none;
  margin-right: 15px;
  width: 100%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.cart-update-option .apply-coupon-wrapper form input:focus,
.cart-update-option .apply-coupon-wrapper form input:active {
  border-color: #c29958;
}

@media only screen and (max-width: 767.98px) {
  .cart-update-option .apply-coupon-wrapper button {
    display: block;
    width: 100%;
    margin-top: 15px;
    border-radius: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .cart-update-option .cart-update {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767.98px) {
  .cart-update-option .cart-update .btn {
    width: 100%;
  }
}

.cart-calculator-wrapper {
  margin-top: 30px;
  background-color: #f8f8f8;
}

.cart-calculator-wrapper h6 {
  padding: 20px 15px 18px;
  margin-bottom: 0;
}

.cart-calculator-wrapper .cart-calculate-items {
  font-weight: 500;
}

.cart-calculator-wrapper .cart-calculate-items .table {
  margin-bottom: 0;
}

.cart-calculator-wrapper .cart-calculate-items .table tr td {
  color: #222222;
  padding: 15px 20px;
}

.cart-calculator-wrapper .cart-calculate-items .table tr td:nth-child(2) {
  color: #222222;
  text-align: right;
}

.cart-calculator-wrapper .cart-calculate-items .table tr td.total-amount {
  color: #c29958;
  font-weight: 700;
}

.cart-calculator-wrapper a {
  border-radius: 0;
  text-align: center;
}

/*--------- Cart Page Wrapper end ---------*/
/*------ Start Compare Page Wrapper -----*/
.compare-table .table tbody tr td {
  text-align: center;
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #ccc;
}

.compare-table .table tbody tr td.first-column {
  min-width: 175px;
  font-size: 15px;
  font-weight: 500;
  color: #222222;
  margin: 0;
}

@media only screen and (max-width: 767.98px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 115px;
  }
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
}

@media only screen and (max-width: 767.98px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 220px;
  }
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 15px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #c29958;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #c29958;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price,
.compare-table .table tbody tr td.pro-color,
.compare-table .table tbody tr td.pro-stock {
  font-size: 14px;
  font-weight: 400;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #e74c3c;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #c29958;
}

/*------ end Compare Page Wrapper -----*/
/*-------- Start My Account Page Wrapper --------*/
.myaccount-tab-menu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #efefef;
  border-bottom: none;
  color: #222222;
  font-weight: 400;
  font-size: 15px;
  display: block;
  padding: 10px 15px;
  text-transform: capitalize;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #efefef;
}

.myaccount-tab-menu a:hover,
.myaccount-tab-menu a.active {
  background-color: #c29958;
  border-color: #c29958;
  color: #fff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767.98px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 575.98px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h5 {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.myaccount-content .welcome a {
  color: #222222;
}

.myaccount-content .welcome a:hover {
  color: #c29958;
}

.myaccount-content .welcome strong {
  font-weight: 500;
  color: #c29958;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  color: #222222;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 400;
  border-bottom: 1px dashed #ccc;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  color: #222222;
  padding: 10px;
  font-weight: 400;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #f4f5f7;
  border-top: 3px solid #c29958;
  border-radius: 5px 5px 0 0;
  font-weight: 400;
  font-size: 15px;
  color: #555555;
  padding: 20px;
}

/*-------- Start My Account Page Wrapper --------*/
/*------ Start Login & Register Page ------*/
.login-reg-form-wrap {
  background-color: #fff;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 23px 30px 30px;
}

@media only screen and (max-width: 575.98px) {
  .login-reg-form-wrap {
    padding: 23px 15px 30px;
  }
}

.login-reg-form-wrap form .create-account {
  margin-top: 25px;
}

.login-reg-form-wrap .login-reg-form-meta a {
  color: #c29958;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign-up-form {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767.98px) {
  .sign-up-form {
    margin-top: 62px;
  }
}

/*------ end Login & Register Page ------*/
/*----- Google map area start -----*/
#google-map {
  height: 500px;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #google-map {
    height: 400px;
  }
}

@media only screen and (max-width: 767.98px) {
  #google-map {
    height: 350px;
  }
}

/*----- Google map area end -----*/
/*------ contact form area start -------*/
.contact-area {
  margin-top: -4px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .contact-area {
    margin-bottom: -5px;
  }
}

.contact-message h2 {
  color: #222222;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 28px;
  text-transform: capitalize;
}

@media only screen and (max-width: 575.98px) {
  .contact-message h2 {
    font-size: 18px;
    padding-bottom: 15px;
  }
}

.contact-message form input,
.contact-message form textarea {
  width: 100%;
  border: none;
  padding: 10px 10px;
  border-bottom: 3px solid transparent;
  background-color: #f7f7f7;
  margin-bottom: 10px;
}

.contact-message form textarea {
  height: 150px;
}

/*------ contact form area start -------*/
/*------ contact info area start -------*/
.contact-info {
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info {
    margin-top: 77px;
  }
}

@media only screen and (max-width: 767.98px) {
  .contact-info {
    margin-top: 60px;
  }
}

.contact-info p {
  padding-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info p {
    padding-bottom: 10px;
  }
}

.contact-info ul li {
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 479.98px) {
  .contact-info ul li {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

.contact-info ul li:last-child {
  border-bottom: none;
}

.contact-info ul li i {
  font-size: 18px;
  padding-right: 10px;
}

.contact-info .working-time h6 {
  padding-bottom: 5px;
}

.contact-info .working-time p {
  padding-bottom: 0;
}

.contact-info .working-time p span {
  color: #222222;
  padding-right: 10px;
}

.contact-title {
  line-height: 1;
  padding-bottom: 22px;
}

@media only screen and (max-width: 575.98px) {
  .contact-title {
    font-size: 18px;
    padding-bottom: 8px;
  }
}

/*------ contact info area end -------*/
/*----- footer area start -----*/
.footer-top {
  background-color: #f7f7f7;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
  .footer-top [class*=col-] .widget-item {
    margin-bottom: 30px;
  }
}

.footer-top [class*=col-]:last-child .widget-item {
  margin-bottom: 0;
}

.widget-title {
  margin-top: -6px;
  margin-bottom: 23px;
}

.contact-block li {
  font-size: 14px;
  margin-bottom: 8px;
}

.contact-block li:last-child {
  margin-bottom: 0;
}

.contact-block li i {
  font-size: 18px;
  padding-right: 5px;
  vertical-align: middle;
}

.contact-block li a {
  color: #555555;
}

.contact-block li a:hover {
  color: #bc1823;
  letter-spacing: 1.1px;
}

.info-list {
  grid-template-columns: auto auto;
}

.info-list li {
  margin-bottom: 8px;
}

.info-list li a {
  font-size: 14px;
  color: #555555;
  text-transform: capitalize;
}

.info-list li a:hover {
  color: #bc1823;
}

.social-link {
  margin-top: 30px;
}

.social-link a {
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  margin-right: 10px;
  color: #777777;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #dedede;
}

.social-link a:last-child {
  margin-right: 0;
}

.social-link a:hover {
  color: #fff;
  background-color: #bc1823;
  border-color: #bc1823;
}

@media only screen and (max-width: 767.98px) {
  .newsletter-wrapper {
    margin-top: 8px;
  }
}

.newsletter-inner {
  position: relative;
  margin-top: 16px;
}

@media only screen and (max-width: 767.98px) {
  .newsletter-inner {
    margin-top: 5px;
  }
}

.newsletter-inner .news-field {
  width: 100%;
  height: 40px;
  border: none;
  padding: 10px 0;
  color: #777777;
  padding-right: 80px;
  background-color: transparent;
  border-bottom: 1px solid #bcbcbc;
}

.newsletter-inner .news-btn {
  top: 0;
  right: 10px;
  line-height: 40px;
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  color: #c29958;
}

.newsletter-inner .news-btn:hover {
  color: #222222;
}

.footer-payment {
  text-align: right;
}

@media only screen and (max-width: 767.98px) {
  .footer-payment {
    text-align: center;
    margin-top: 30px;
  }
}

.footer-bottom {
  padding: 20px 0;
}

.copyright-text p a {
  color: #bc1823;
}

/*----- footer area end -----*/




/* new css */
.adress-flex {
  display: flex;
  justify-content: space-between;
}

.adress-flex i {
  color: #bc1823;
  font-size: 18px;
}

.adress-flex li a {
  color: #555555;
}

.header-top-socialicon .nav .curreny-wrap a i {
  color: #bc1823;
  font-size: 15px;

}

.add-sec {
  padding-bottom: unset !important;
}

.footer-end {
  text-align: end;
}

.adress-flex li a:hover {
  color: #bc1823;

}

.adress-flex li {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
}



@media only screen and (min-width: 576px) and (max-width: 767px) {


  .banner-odd .mx-5 {
    margin-right: unset !important;
    margin-left: unset !important;
  }

}

/* new css */
/* category css start*/
.product-short {
  position: relative;
}

.product-short input {
  width: 100%;
  padding: 5px 10px;
  position: relative;
}

.product-short button {
  background: #bc1823;
  padding: 3px 8px;
  position: absolute;
  right: 5px;
}

.product-short button i {
  color: #fff;
}

.price-old a {
  color: #cba45a !important;
}

.prod-detcode-color a {
  color: #bc1823 !important;
}

/* category css end*/
.zoom_image {
  overflow: hidden;
  max-width: 620px;
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0 0 1000px 10px black;
  cursor: zoom-in;
}

&.zoom_mode_active {
  cursor: zoom-out;
}

.zoom_image img {
  max-width: 100%;
  width: 100%;
  height: auto;

}

.mobile-main-header .mobile-logo img {
  width: 100%;
}

/* react css */


.carousel-caption {

  text-align: unset !important;
}

.carousel-indicators {
  display: none !important;
}

.carousel-caption {
  position: absolute;
  bottom: 5.25rem;

}

.homebanner-2 {
  bottom: 2.10rem;
}

.react-tabs__tab--selected {
  border-color: #fff !important;

}
.slick-next,
.slick-prev {
  display: none !important;
}
.scroll-to-top {
  background-color: #bc1823 !important;
  color: #fff;
}
.scroll-to-top:hover {
  background-color: #000;
}
/* new css */
/* category css start*/
.product-short {
  position: relative;
}

.product-short input {
  width: 100%;
  padding: 5px 10px;
  position: relative;
}

.product-short button {
  background: #bc1823;
  padding: 3px 8px;
  position: absolute;
  right: 5px;
}

.product-short button i {
  color: #fff;
}

.price-old a {
  color: #cba45a !important;
}

.prod-detcode-color a {
  color: #bc1823 !important;

}

.paginatoin-area .pagination-box li button {
  color: #222222;
  height:36px;
  width:36px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  line-height: 36px;
  background-color: #e9dddd;
  border-radius: 50%;
}

.paginatoin-area .pagination-box li button i {
  font-size: 30px;
  line-height: 36px;
}
.paginatoin-area .pagination-box li.active button {
  color: #fff;
  background-color: #bc1823;
}

.text-transform {
  text-transform: uppercase !important;
}

/* modal popup */

.entry-popup .modal-body {
  padding: 17px 30px;
}
.entry-popup .modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 3px solid #bc1823 !important;
  border-radius: .3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}


.entry-popup .modal-body form .form-control {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  color: #495057;
  display: block;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  line-height: 1.5;
  padding: .375rem .75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;
}


.modal-header {
  align-items: center;
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
}



.entry-popup .modal-header .modal-title {
  font-size: 25px;
  font-weight: 600!important;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}
.modal-body {
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  position: relative;
}

.entry-popup .modal-body {
  padding: 17px 30px;
}

.modal-footer {
  align-items: center;
  background-color: var(--bs-modal-footer-bg);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap)*.5);
}
.main-btn {
  background: linear-gradient(90deg, #bc1823,#46080c);
  border: 1px solid #bc1823;
  border-radius: 5px;
  box-shadow: 0 5px 16px 0 rgba(58,46,34,.51);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Barlow,sans-serif!important;
  font-size: 18px;
  font-weight: 600;
  padding: 0 30px;
  text-align: center;
  text-transform: uppercase;
  transition: .4s ease-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle; 
  white-space: nowrap;
  z-index: 5;
  line-height: 48px;

}

.entry-popup .modal-header {
  align-items: center;
  padding: 7px 40px;
}


.modal-header {
  align-items: center;
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
}

.our-products-home{
  width: 100% !important;
}
.section-title h6{
  line-height: 27px;
 
}


.home-banner-left{
  text-align: left !important;

}

.home-banner-right{
  text-align: end !important;
}

.contact-message form small{
  color: red;
 padding-bottom: 20px;
}

@media only screen and (min-width:1200px){
  .carousel-caption{ 
    top: 50%;
   transform: translateY(-50%); 
   padding-top: 0px;
   padding-bottom: 0px;
 }
} 


@media screen and (min-width:992px) and (max-width:1199px){
 .carousel-caption {
   top: 45%;
   transform: translateY(-50%);
   padding-top: 0px;
   padding-bottom: 0px;
}
}

.modal.show .modal-dialog {
  margin-top: 85px;
}

.prod-detcode-color span {
  color: #bc1823 !important;
}


.scroll-to-top {
  background-color: white;
  right: 5px;
  bottom: 30px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
  border-radius: 7px;
  width: 40px;
  height: 40px;
  box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
  border: none;
}

.contact-info li a{
  color: #555555;
}

.product-banner-statistics .slick-next, .product-banner-statistics .slick-prev {
  display: block !important; 
}

.product-banner-statistics .product-banner-carousel{
 position: relative;
}

.product-banner-statistics .product-banner-carousel .slick-prev{
 position:absolute;
 top: 50%;
 transform: translateY(-50%);
 left: 15px;
 background: #bc1823;
 color: #fff;
 padding: 5px;
 z-index: 2;
 height: 45px;
 width: 45px;
 line-height: 32px;
 border-radius: 50%;
 text-align: center;
 cursor: pointer;
}

.product-banner-statistics .product-banner-carousel .slick-next{
 position:absolute;
 top: 50%;
 transform: translateY(-50%);
 right: 15px;
 background: #bc1823;
 color: #fff;
 padding: 5px;
 z-index: 2;
 height: 45px;
 width: 45px;
 line-height: 32px;
 border-radius: 50%;
 text-align: center;
 cursor: pointer;
}

.product-area .slick-slider .slick-prev{
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -4%;
  background: #bc1823;
  color: #fff;
  padding: 5px;
  z-index: 2;
  height: 45px;
  width: 45px;
  line-height: 32px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.product-area .slick-slider .slick-next{
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -4%;
  background: #bc1823;
  color: #fff;
  padding: 5px;
  z-index: 2;
  height: 45px;
  width: 45px;
  line-height: 32px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.product-area .slick-slider .slick-next:hover{
  background-color: #fff;
  border: 1px solid #bc1823;
  color: #bc1823;
}

.product-area .slick-slider .slick-prev:hover{
  background-color: #fff;
  border: 1px solid #bc1823;
  color: #bc1823;
}
.product-banner-statistics .product-banner-carousel .slick-next:hover{
  background-color: #fff;
  border: 1px solid #bc1823;
  color: #bc1823;
}

.product-banner-statistics .product-banner-carousel .slick-prev:hover{
  background-color: #fff;
  border: 1px solid #bc1823;
  color: #bc1823;
}

.related-products .product-carousel-4 .slick-prev:hover{
  background-color: #fff;
  border: 1px solid #bc1823;
  color: #bc1823;
}

.related-products .product-carousel-4 .slick-next:hover{
  background-color: #fff;
  border: 1px solid #bc1823;
  color: #bc1823;
}

.related-products .product-carousel-4 .slick-prev{
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -4%;
  background: #bc1823;
  color: #fff;
  padding: 5px;
  z-index: 2;
  height: 45px;
  width: 45px;
  line-height: 32px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}
.related-products .product-carousel-4 .slick-next{
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -4%;
  background: #bc1823;
  color: #fff;
  padding: 5px;
  z-index: 2;
  height: 45px;
  width: 45px;
  line-height: 32px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}



.related-products .product-carousel-4{
 position: relative;
}



.shop-main-wrapper .quantity-cart-box{
  margin-top: 25px;
}



.carousel-control-prev {
    background: #bc1823;
    height: 50px;
    width: 50px;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5%;
    border-radius: 50%;

}

.carousel-control-prev {
  background: #bc1823;
  height: 50px;
  width: 50px;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5%;
  border-radius: 50%;

}

.carousel-control-next {
  background: #bc1823;
  height: 50px;
  width: 50px;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5%;
  border-radius: 50%;
}

.react-tabs__tab--selected button{
 
  color: #bc1823;
  border: 2px solid #bc1823 !important;
  padding: 0 10px;
  border-radius: 10px;
}
.contact-info li a:hover {
  color: #b51010;
}