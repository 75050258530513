@media only screen and (min-width: 280px) and (max-width: 767px) {

  .top-bar-right .product-short {
    margin-top: 25px;
  }

  .top-bar-right .product-short {

    width: 80% !important;
  }

  .shop-top-bar .product-amount {
    width: 100%;
    text-align: center;
  }

  .home-banner .banner-h img {
    height: 300px !important;

  }

  .carousel-caption {
    position: absolute;
    bottom: 2.25rem !important;
  }

  .carousel-caption {
    display: flex;
    align-items: center;
  }


}


@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .hero-slider-content .slide-title {
    font-size: 50px;

  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .hero-slider-content .slide-title {
    font-size: 50px;

  }
}

@media only screen and (min-width: 450px) and (max-width: 479px) {

.width-35 {
  width: 69% !important;
}
}
@media only screen and (min-width: 480px) and (max-width: 575px) {

  .width-35 {
    width: 58% !important;
}

  .manufacturer-name {
    font-size: 12px !important;

}
.manufacturer-name {
  font-size: 12px;
  padding-bottom: 5px;
  line-height: 1;
}

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }

}




@media only screen and (min-width: 576px) and (max-width: 767px) {
  .width-35 {
    width: 109% !important;
  }
}

@media only screen and (min-width: 992px) {
  .about-tittle-mobile-view{
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .contact-area{
    padding-bottom: unset !important;
  }
  .about-tittle-desc-view{
    display: none !important;

  }
  .offcanvas-header .offcanvas-title img {
    width: 50% !important;
  }

  .width-20 {
    width: 50% !important;
}

  .product-details-des .product-name {

    padding-bottom: 14px !important;
  }


  .d-flex {
    display: block !important;

  }

  .product-details-des {

    margin-right: unset !important;
    margin-left: unset !important;

  }

  .product-area .product-container .slick-slider .slick-prev {
    display: none !important; 
  }
  
  .product-area .product-container .slick-slider .slick-next {
    display: none !important; 
  }

  .product-banner-statistics .product-banner-carousel .slick-next{
    display: none !important;
  }

  .product-banner-statistics .product-banner-carousel .slick-prev{
    display: none !important;
  }

  .related-products .product-carousel-4 .slick-next, .related-products .product-carousel-4 .slick-prev {
    display: none !important; 
  }

}





@media only screen and (max-width: 575px) {
  .carousel-control-prev {
   display: none !important;
}

.carousel-control-next {
display: none !important;
}


}


@media only screen and (max-width: 480px) {

  .product-thumb img {
    width: 100%;
    margin: auto;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {

  .product-thumb img {
    width: 95%;
    margin: auto;
  }

}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .product-thumb img {
    width: 95%;
    margin: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .product-thumb img {
    width: 96%;
    margin: auto;
  }

  .product-area .slick-slider .slick-next{
     right: 15px;
  }

  .product-area .slick-slider .slick-prev{
    left: 15px;
 }

 .related-products .product-carousel-4 .slick-prev{
    left: -15px !important;
}
.related-products .product-carousel-4 .slick-next{
  right: -15px !important;
}
.product-area .slick-slider .slick-prev {
  left: -15px !important;
}
.product-area .slick-slider .slick-next {
  right: -15px !important;
}
}

@media screen and (min-width:992px){
  .product-area .product-container .slick-slider .slick-prev {
    display: block !important; 
  }
  
  .product-area .product-container .slick-slider .slick-next {
    display: block !important; 
  }

  .related-products .product-carousel-4 .slick-next, .related-products .product-carousel-4 .slick-prev {
    display: block !important; 
  }
}

@media screen and (max-width:479px){

.btn-hero {

  padding: 9px 20px  !important;

}
.hero-slider-content .slide-title {
  font-size: 20px  !important;
}
.btn-hero {

  margin-top: 20px !important;
}

.home-banner .banner-h img {
  height: 180px !important;
}
.carousel-caption {
  position: absolute;
  bottom: 1.10rem !important;
}
}

@media only screen and (min-width: 280px) and (max-width: 360px) {

.g-recaptcha {
  transform: scale(0.88) ;
  -webkit-transform: scale(0.7);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
}